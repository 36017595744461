import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, record_type, int32_type, class_type, unit_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DownloadReportRequest, ReportRequest, ReportStatusChanged_$reflection, ApiMessageResponse_$reflection, PagedDataResponse$1_$reflection, DownloadReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { withUrl, signalr as signalr_1, configureLogging, build, register } from "../SignalR.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { createObj, defaultOf, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { downloadReport, getDownloadableReports } from "../API/ReportAPI.fs.js";
import { Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Default_pagedData } from "../Models.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { append, map, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { formatDateTime } from "../Domain/Formatter.fs.js";
import { ExportReportStatusModule_toInt } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";

export class State extends Record {
    constructor(ReportToDownload, ReportDisplayName, PagedDataDeferred, ExportDeferred, SignalRHub, Page) {
        super();
        this.ReportToDownload = ReportToDownload;
        this.ReportDisplayName = ReportDisplayName;
        this.PagedDataDeferred = PagedDataDeferred;
        this.ExportDeferred = ExportDeferred;
        this.SignalRHub = SignalRHub;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.DownloadReport.State", [], State, () => [["ReportToDownload", string_type], ["ReportDisplayName", string_type], ["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(DownloadReportResponse_$reflection()))], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SignalRHub", class_type("SignalR.IHubConnection")], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "DownloadAsync", "StartReportDownload", "SignalRReportStatusChanged", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.DownloadReport.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(DownloadReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(DownloadReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item1", string_type], ["Item2", string_type]], [["Item", ReportStatusChanged_$reflection()]], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

export function init(user) {
    let signalR;
    const patternInput = register("ReportStatusChanged", (Item) => (new Msg(3, [Item])), build(configureLogging(1, (signalR = (new signalr_1.HubConnectionBuilder()), withUrl(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api`, signalR)))));
    return [new State("", "", new Deferred$1(0, []), new Deferred$1(0, []), patternInput[0], 1), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    }), patternInput[1]]))];
}

export function update(msg, state) {
    let input_3;
    switch (msg.tag) {
        case 4:
            if (hasNextPage(state)) {
                return [new State(state.ReportToDownload, state.ReportDisplayName, state.PagedDataDeferred, state.ExportDeferred, state.SignalRHub, state.Page + 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 5:
            if (hasPreviousPage(state)) {
                return [new State(state.ReportToDownload, state.ReportDisplayName, state.PagedDataDeferred, state.ExportDeferred, state.SignalRHub, state.Page - 1), singleton((dispatch_2) => {
                    dispatch_2(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 0:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.ReportToDownload, state.ReportDisplayName, new Deferred$1(1, []), state.ExportDeferred, state.SignalRHub, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getDownloadableReports, new ReportRequest("", "", new Int32Array([]), new Int32Array([]), new Int32Array([]), new Int32Array([]), new Int32Array([]), new Int32Array([]), new Int32Array([]), [], new Int32Array([]), [], [], [], [], [], [], [], new Int32Array([]), [], [], "", "None", "None", defaultOf(), "", "", defaultOf(), defaultOf(), state.Page), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Alert_error(Alert_title("Reports Available for Download", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(state.ReportToDownload, state.ReportDisplayName, new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.ExportDeferred, state.SignalRHub, state.Page), command_1];
                }
                default:
                    return [state, Cmd_none()];
            }
        case 2:
            return [new State(msg.fields[0], msg.fields[1], state.PagedDataDeferred, state.ExportDeferred, state.SignalRHub, state.Page), singleton((dispatch_3) => {
                dispatch_3(new Msg(1, [new AsyncMsg$1(0, [])]));
            })];
        case 1:
            switch (msg.fields[0].tag) {
                case 0:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.ReportToDownload, state.ReportDisplayName, state.PagedDataDeferred, new Deferred$1(1, []), state.SignalRHub, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, downloadReport, new DownloadReportRequest(state.ReportDisplayName, state.ReportToDownload), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
                case 2:
                    return [new State(state.ReportToDownload, state.ReportDisplayName, state.PagedDataDeferred, new Deferred$1(2, [void 0]), state.SignalRHub, state.Page), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        default:
            return [state, singleton((dispatch) => {
                dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
    }
}

export function renderTopSection(state, dispatch) {
    let elems_8, elems_7, elems, matchValue, pagedData, elems_6, elems_5, elems_2, elems_1, elems_4, elems_3;
    return createElement("div", createObj(ofArray([["className", "box"], (elems_8 = [createElement("h1", {
        className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
        children: "Scheduled Reports",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_5 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg) => {
        dispatch(new Msg(5, []));
    }], (elems_2 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_1 = [createElement("i", {
        children: ["navigate_before"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("span", {
        className: join(" ", ["is-sr-only"]),
        children: "Previous",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_1) => {
        dispatch(new Msg(4, []));
    }], (elems_4 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_3 = [createElement("i", {
        children: ["navigate_next"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("span", {
        className: join(" ", ["is-sr-only"]),
        children: "Next",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function render(state, dispatch) {
    let elems_7, elems_6;
    return createElement("div", createObj(singleton((elems_7 = [renderTopSection(state, dispatch), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_6 = toList(delay(() => {
        let elems_5, elems_4, elems_1, children, elems_3;
        const matchValue = state.PagedDataDeferred;
        switch (matchValue.tag) {
            case 1:
                return singleton_1(render_1());
            case 2: {
                const pagedData = matchValue.fields[0];
                if (pagedData.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "No reports were found for this user",
                    }));
                }
                else {
                    const tdClasses = ofArray(["px-3", "py-2"]);
                    const tableHeader = (header) => createElement("th", {
                        className: join(" ", ["px-3", "py-2", "has-text-link"]),
                        children: header,
                    });
                    const tableData = (text) => createElement("td", {
                        className: join(" ", tdClasses),
                        children: text,
                    });
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_5 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_4 = [createElement("thead", createObj(singleton((elems_1 = [(children = ofArray([tableHeader("Name"), tableHeader("Submitted"), tableHeader("Description"), tableHeader("Download")]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))), createElement("tbody", createObj(singleton((elems_3 = toList(delay(() => map((item) => {
                        let elems_2;
                        return createElement("tr", createObj(singleton((elems_2 = toList(delay(() => append(singleton_1(tableData(item.DisplayName)), delay(() => append(singleton_1(tableData(formatDateTime(item.CreatedDate))), delay(() => append(singleton_1(tableData(item.Description)), delay(() => {
                            let elems;
                            return (item.Status === ExportReportStatusModule_toInt(3)) ? singleton_1(createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems = [createElement("a", {
                                children: "Download",
                                onClick: (_arg) => {
                                    dispatch(new Msg(2, [item.Name, item.DisplayName]));
                                },
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : singleton_1(tableData("Processing"));
                        })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
                    }, pagedData.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
                }
            }
            default:
                return singleton_1(createElement("div", {
                    className: join(" ", ["has-text-centered"]),
                    children: "Query has not yet run",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]))));
}

