import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Http_withTimeout, Http_content, Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_send } from "../fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { BodyContent, HttpMethod } from "../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { getIdToken } from "./AuthenticationAPI.fs.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { DeleteUserRoleResponse_$reflection, DeleteUserRoleRequest_$reflection, UpdateUserRoleResponse_$reflection, UpdateUserRoleRequest_$reflection, CreateUserWithRoleResponse_$reflection, CreateUserWithRoleRequest_$reflection, GetUserListResponse_$reflection, UserResponse_$reflection, ApiMessageResponseModule_mapMessages } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { SimpleJson_parse, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { Convert_serialize, Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";

export function getCurrent() {
    return singleton.Delay(() => {
        let req_2, req_1;
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/current`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(UserResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function getAllUsers() {
    return singleton.Delay(() => {
        let req_2, req_1;
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/all`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(GetUserListResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function createUserWithRole(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(CreateUserWithRoleRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/createUserWithRole`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(CreateUserWithRoleResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function updateUserRole(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(UpdateUserRoleRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/updateUserWithRole`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(UpdateUserRoleResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function deleteUserRole(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(DeleteUserRoleRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/deleteUserWithRole`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(DeleteUserRoleResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

