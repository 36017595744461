import { Record, Union } from "../FootPrint.Portal.UI/fable_modules/fable-library.4.10.0/Types.js";
import { record_type, bool_type, string_type, int32_type, union_type } from "../FootPrint.Portal.UI/fable_modules/fable-library.4.10.0/Reflection.js";

export class FileType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Account", "AdvancedShippingNotice", "Reservation", "ShippingOrder", "Material"];
    }
}

export function FileType_$reflection() {
    return union_type("FootPrint.Portal.CustomTypes.FileType", [], FileType, () => [[], [], [], [], []]);
}

export function FileTypeModule_optional(value) {
    switch (value) {
        case "Account":
            return new FileType(0, []);
        case "ASN":
            return new FileType(1, []);
        case "Reservation":
            return new FileType(2, []);
        case "940":
            return new FileType(3, []);
        case "Material":
            return new FileType(4, []);
        default:
            return void 0;
    }
}

export function FileTypeModule_value(fileType) {
    switch (fileType.tag) {
        case 1:
            return "ASN";
        case 2:
            return "Reservation";
        case 4:
            return "Material";
        case 3:
            return "940";
        default:
            return "Account";
    }
}

export class OrderStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Submitted", "Processing", "Failed", "Completed"];
    }
}

export function OrderStatus_$reflection() {
    return union_type("FootPrint.Portal.CustomTypes.OrderStatus", [], OrderStatus, () => [[], [], [], []]);
}

export function OrderStatusModule_optional(value) {
    switch (value) {
        case "Submitted":
            return new OrderStatus(0, []);
        case "Processing":
            return new OrderStatus(1, []);
        case "Failed":
            return new OrderStatus(2, []);
        case "Completed":
            return new OrderStatus(3, []);
        default:
            return void 0;
    }
}

export function OrderStatusModule_value(orderStatus) {
    switch (orderStatus.tag) {
        case 1:
            return "Processing";
        case 2:
            return "Failed";
        case 3:
            return "Completed";
        default:
            return "Submitted";
    }
}

export class OrderType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Backorder", "Cancelled", "Completed", "Created", "Error", "Hold", "Processing", "Ready", "Wait"];
    }
}

export function OrderType_$reflection() {
    return union_type("FootPrint.Portal.CustomTypes.OrderType", [], OrderType, () => [[], [], [], [], [], [], [], [], []]);
}

export function OrderTypeModule_optional(value) {
    switch (value) {
        case "Backorder":
            return new OrderType(0, []);
        case "Cancelled":
            return new OrderType(1, []);
        case "Completed":
            return new OrderType(2, []);
        case "Created":
            return new OrderType(3, []);
        case "Error":
            return new OrderType(4, []);
        case "Hold":
            return new OrderType(5, []);
        case "Processing":
            return new OrderType(6, []);
        case "Ready":
            return new OrderType(7, []);
        case "Wait":
            return new OrderType(8, []);
        default:
            return void 0;
    }
}

export function OrderTypeModule_value(orderType) {
    switch (orderType.tag) {
        case 1:
            return "Cancelled";
        case 2:
            return "Completed";
        case 3:
            return "Created";
        case 4:
            return "Error";
        case 5:
            return "Hold";
        case 6:
            return "Processing";
        case 7:
            return "Ready";
        case 8:
            return "Wait";
        default:
            return "Backorder";
    }
}

export class DatexOrderStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ThirdPartyOutbound", "AutoPick", "AutomatedOutbound", "B2BSalesOrder", "B2BXDock", "B2CSalesOrder", "BillAndHoldSO", "BuildSO", "InactiveInventorySO", "InactiveOutboundRTV", "Internal2WConsume", "InternalSalesOrder", "InternationalSO", "LaunchOrder", "LaunchSO", "MLO", "Outbound3rdPartyWarehouseTransfer", "OutboundMaterialTransfer", "OutboundOwnerTransfer", "OutboundReDelivery", "OutboundRMA", "OutboundRTV", "OutboundTransLoad", "OutboundWarehouseTransfer", "Production", "Reservation", "SalesOrder", "SalesOrderAAI", "SalesOrderAutomation", "SalesOrderBypassSoftAllocation", "SalesOrderOP", "SelfInstall", "StratBuy", "TechInstall", "UsedMaterialSO", "InboundASN", "InboundWarehouseTransfer", "PurchaseOrder", "StratBuyIn"];
    }
}

export function DatexOrderStatus_$reflection() {
    return union_type("FootPrint.Portal.CustomTypes.DatexOrderStatus", [], DatexOrderStatus, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function DatexOrderStatusModule_optional(value) {
    switch (value) {
        case "3rd Party Outbound":
            return new DatexOrderStatus(0, []);
        case "Auto Pick":
            return new DatexOrderStatus(1, []);
        case "Automated Outbound":
            return new DatexOrderStatus(2, []);
        case "B2B Sales Order":
            return new DatexOrderStatus(3, []);
        case "B2B XDock":
            return new DatexOrderStatus(4, []);
        case "B2C Sales Order":
            return new DatexOrderStatus(5, []);
        case "Bill and Hold SO":
            return new DatexOrderStatus(6, []);
        case "BuildSO":
            return new DatexOrderStatus(7, []);
        case "Inactive Inventory SO":
            return new DatexOrderStatus(8, []);
        case "Inactive Outbound RTV":
            return new DatexOrderStatus(9, []);
        case "Internal 2W Consume":
            return new DatexOrderStatus(10, []);
        case "Internal Sales Order":
            return new DatexOrderStatus(11, []);
        case "International SO":
            return new DatexOrderStatus(12, []);
        case "Launch Order":
            return new DatexOrderStatus(13, []);
        case "Launch SO":
            return new DatexOrderStatus(14, []);
        case "MLO":
            return new DatexOrderStatus(15, []);
        case "Outbound 3rd Party Warehouse Transfer":
            return new DatexOrderStatus(16, []);
        case "Outbound Material Transfer":
            return new DatexOrderStatus(17, []);
        case "Outbound Owner Transfer":
            return new DatexOrderStatus(18, []);
        case "Outbound Re-Delivery":
            return new DatexOrderStatus(19, []);
        case "Outbound RMA":
            return new DatexOrderStatus(20, []);
        case "Outbound RTV":
            return new DatexOrderStatus(21, []);
        case "Outbound TransLoad":
            return new DatexOrderStatus(22, []);
        case "Outbound Warehouse Transfer":
            return new DatexOrderStatus(23, []);
        case "Production":
            return new DatexOrderStatus(24, []);
        case "Reservation":
            return new DatexOrderStatus(25, []);
        case "Sales Order":
            return new DatexOrderStatus(26, []);
        case "Sales Order AAI":
            return new DatexOrderStatus(27, []);
        case "Sales Order Automation":
            return new DatexOrderStatus(28, []);
        case "Sales Order Bypass Soft Allocation":
            return new DatexOrderStatus(29, []);
        case "Sales Order OP":
            return new DatexOrderStatus(30, []);
        case "Self Install":
            return new DatexOrderStatus(31, []);
        case "Strat-Buy":
            return new DatexOrderStatus(32, []);
        case "Tech Install":
            return new DatexOrderStatus(33, []);
        case "Used Material SO":
            return new DatexOrderStatus(34, []);
        case "Inbound ASN":
            return new DatexOrderStatus(35, []);
        case "Inbound Warehouse Transfer":
            return new DatexOrderStatus(36, []);
        case "Purchase Order":
            return new DatexOrderStatus(37, []);
        case "Strat-Buy In":
            return new DatexOrderStatus(38, []);
        default:
            return void 0;
    }
}

export function DatexOrderStatusModule_value(orderStatus) {
    switch (orderStatus.tag) {
        case 1:
            return "Auto Pick";
        case 2:
            return "Automated Outbound";
        case 3:
            return "B2B Sales Order";
        case 4:
            return "B2B XDock";
        case 5:
            return "B2C Sales Order";
        case 6:
            return "Bill and Hold SO";
        case 7:
            return "BuildSO";
        case 8:
            return "Inactive Inventory SO";
        case 9:
            return "Inactive Outbound RTV";
        case 10:
            return "Internal 2W Consume";
        case 11:
            return "Internal Sales Order";
        case 12:
            return "International SO";
        case 13:
            return "Launch Order";
        case 14:
            return "Launch SO";
        case 15:
            return "MLO";
        case 16:
            return "Outbound 3rd Party Warehouse Transfer";
        case 17:
            return "Outbound Material Transfer";
        case 18:
            return "Outbound Owner Transfer";
        case 19:
            return "Outbound Re-Delivery";
        case 20:
            return "Outbound RMA";
        case 21:
            return "Outbound RTV";
        case 22:
            return "Outbound TransLoad";
        case 23:
            return "Outbound Warehouse Transfer";
        case 24:
            return "Production";
        case 25:
            return "Reservation";
        case 26:
            return "Sales Order";
        case 27:
            return "Sales Order AAI";
        case 28:
            return "Sales Order Automation";
        case 29:
            return "Sales Order Bypass Soft Allocation";
        case 30:
            return "Sales Order OP";
        case 31:
            return "Self Install";
        case 32:
            return "Strat-Buy";
        case 33:
            return "Tech Install";
        case 34:
            return "Used Material SO";
        case 35:
            return "Inbound ASN";
        case 36:
            return "Inbound Warehouse Transfer";
        case 37:
            return "Purchase Order";
        case 38:
            return "Strat-Buy In";
        default:
            return "3rd Party Outbound";
    }
}

export function FootPrintClaimModule_fromInt(value) {
    switch (value) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        case 5:
            return 5;
        case 6:
            return 6;
        case 7:
            return 7;
        case 8:
            return 8;
        case 9:
            return 9;
        case 10:
            return 10;
        case 11:
            return 11;
        case 12:
            return 12;
        case 13:
            return 13;
        case 14:
            return 14;
        case 16:
            return 16;
        default:
            return void 0;
    }
}

export function FootPrintClaimModule_toString(value) {
    switch (value) {
        case 1:
            return "Upload ASNs";
        case 2:
            return "Upload Reservations";
        case 3:
            return "Upload 940s";
        case 4:
            return "Manage Accounts";
        case 5:
            return "Manage Materials";
        case 6:
            return "View Inbound Details Query";
        case 7:
            return "View Inbound Summary Query";
        case 8:
            return "View Onhand Details Query";
        case 9:
            return "View Outbound Details Query";
        case 10:
            return "View Outbound Summary Query";
        case 11:
            return "View Reservations Query";
        case 12:
            return "View Materials Query";
        case 13:
            return "View Shipping Matrix Query";
        case 14:
            return "Edit Roles And Permissions";
        default:
            return "Unknown";
    }
}

export function FootPrintClaimModule_toInt(value) {
    return value;
}

export class FootPrintUserClaim extends Record {
    constructor(ClaimId, Name, Setting) {
        super();
        this.ClaimId = (ClaimId | 0);
        this.Name = Name;
        this.Setting = Setting;
    }
}

export function FootPrintUserClaim_$reflection() {
    return record_type("FootPrint.Portal.CustomTypes.FootPrintUserClaim", [], FootPrintUserClaim, () => [["ClaimId", int32_type], ["Name", string_type], ["Setting", bool_type]]);
}

export class FootPrintClaimAvailable extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function FootPrintClaimAvailable_$reflection() {
    return record_type("FootPrint.Portal.CustomTypes.FootPrintClaimAvailable", [], FootPrintClaimAvailable, () => [["Id", int32_type], ["Name", string_type]]);
}

export function YesNoTBDModule_toInt(value) {
    return value;
}

export function YesNoTBDModule_toString(value) {
    switch (value) {
        case 1:
            return "Yes";
        case 2:
            return "No";
        default:
            return "TBD";
    }
}

export function ExportReportStatusModule_toInt(reportStatus) {
    return reportStatus;
}

export function ExportReportStatusModule_toString(reportStatus) {
    switch (reportStatus) {
        case 1:
            return "Submitted";
        case 2:
            return "Processing";
        case 3:
            return "Completed";
        default:
            return "Failed";
    }
}

export function ExportReportStatusModule_fromString(reportStatus) {
    switch (reportStatus) {
        case "Submitted":
            return 1;
        case "Processing":
            return 2;
        case "Completed":
            return 3;
        default:
            return 4;
    }
}

