import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ReportRequest, ApiMessageResponse_$reflection, UserResponse_$reflection, PagedDataResponse$1_$reflection, InboundDetailsReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, bool_type, class_type, enum_type, int32_type, option_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { StateSelectFilterHelper_renderTypeAheadSearch, StateSelectFilterHelper_renderSearch, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractTypeAheadFilter, StateSelectFilterModule_addTypeAheadSearchFilter, StateSelectFilterModule_addMultiSearchFilter, TypeStateHeldModule_toTypeAheadSearchState, TypeStateHeldModule_toMultiSearchState, StateSelectFilterHelper_selectedItemsDisplayValues, StateSelectFilterHelper_selectedItemsIds, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { DataSource, DataSourceItem, Default_pagedData, SearchFilterKey, DataSource_$reflection, DataSourceItem_$reflection } from "../Models.fs.js";
import { render as render_4, update as update_4, init as init_4, MultiDateRangeTypeModule_toInt, Msg_$reflection as Msg_$reflection_3, State_$reflection as State_$reflection_1 } from "../Components/MultiDateSearch.fs.js";
import { render as render_5, update as update_5, OrderTypeInboundOrOutbound, init as init_3, Msg_$reflection as Msg_$reflection_4, State_$reflection as State_$reflection_2 } from "../Components/OrderTypeStatus.fs.js";
import { render as render_2, update as update_3, init as init_5, Msg_$reflection as Msg_$reflection_5, State_$reflection as State_$reflection_3 } from "../Components/ExportReportDialog.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { render as render_1, State as State_3, update as update_2, extractName as extractName_1, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_1 } from "../Components/MultiSelectSearch.fs.js";
import { render as render_3, update as update_1, extractName, init as init_2, Msg_$reflection as Msg_$reflection_2 } from "../Components/TypeAheadSearch.fs.js";
import { value as value_62 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { Int32_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { createObj, comparePrimitives, numberHash, int32ToString, equals, defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getAWSSKUs, getMaterials, getWarehouses, getOwners, getProjects } from "../API/DataSourceAPI.fs.js";
import { isEmpty, filter as filter_3, empty, map, singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { userHasClaim } from "../Components/ClaimHelpers.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_text, Toast_title, Toast_info, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportInboundDetails, getInboundDetails } from "../API/ReportAPI.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { contains, map as map_1, sortBy, where } from "../fable_modules/fable-library.4.10.0/Array.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map as map_2, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_6 } from "../Components/Loader.fs.js";
import { formatDecimal, formatDateTime, formatInt } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(PagedDataDeferred, CurrentUser, ExportDeferred, SelectFilters, SelectedOrderStatus, SelectedOrderType, SelectedSupplier, DateFilter, DateRangeType, DateStart, DateEnd, OrderTypeStatus, ExportReportState, ShowProjectSearch, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.CurrentUser = CurrentUser;
        this.ExportDeferred = ExportDeferred;
        this.SelectFilters = SelectFilters;
        this.SelectedOrderStatus = SelectedOrderStatus;
        this.SelectedOrderType = SelectedOrderType;
        this.SelectedSupplier = SelectedSupplier;
        this.DateFilter = DateFilter;
        this.DateRangeType = (DateRangeType | 0);
        this.DateStart = DateStart;
        this.DateEnd = DateEnd;
        this.OrderTypeStatus = OrderTypeStatus;
        this.ExportReportState = ExportReportState;
        this.ShowProjectSearch = ShowProjectSearch;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.InboundDetailsQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(InboundDetailsReportResponse_$reflection()))], ["CurrentUser", UserResponse_$reflection()], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["SelectedOrderStatus", option_type(DataSourceItem_$reflection())], ["SelectedOrderType", option_type(DataSourceItem_$reflection())], ["SelectedSupplier", option_type(DataSourceItem_$reflection())], ["DateFilter", State_$reflection_1()], ["DateRangeType", enum_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateRangeType", int32_type, [["Choose", 0], ["Created", 1], ["Processed", 2], ["Completed", 3], ["Cancelled", 4]])], ["DateStart", option_type(class_type("System.DateTime"))], ["DateEnd", option_type(class_type("System.DateTime"))], ["OrderTypeStatus", State_$reflection_2()], ["ExportReportState", State_$reflection_3()], ["ShowProjectSearch", bool_type], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "MultiSelectSearchMsg", "TypeAheadSearchMsg", "SetMultiSearchItems", "SetTypeAheadSearchItems", "DateFilterMsg", "OrderTypeStatusMsg", "SetOrderStatus", "SetOrderType", "ExportReportMsg", "RebuildProjectFilters", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.InboundDetailsQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(InboundDetailsReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(InboundDetailsReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]], [["Item", SelectedItemsChangedData_$reflection()]], [["Item", DataSource_$reflection()]], [["Item", Msg_$reflection_3()]], [["Item", Msg_$reflection_4()]], [["Item", option_type(DataSourceItem_$reflection())]], [["Item", option_type(DataSourceItem_$reflection())]], [["Item", Msg_$reflection_5()]], [], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    let matchValue_4, matchValue_5;
    let Description;
    const matchValue = state.ExportReportState.ReportDescription;
    Description = ((matchValue != null) ? matchValue : "");
    let OrderStatus;
    const matchValue_1 = state.SelectedOrderStatus;
    OrderStatus = ((matchValue_1 != null) ? (new Int32Array([value_62(Int32_fromString(matchValue_1.Id))])) : (new Int32Array(0)));
    let OrderType;
    const matchValue_2 = state.SelectedOrderType;
    OrderType = ((matchValue_2 != null) ? (new Int32Array([value_62(Int32_fromString(matchValue_2.Id))])) : (new Int32Array(0)));
    const OwnerIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
    return new ReportRequest("InboundDetails.xlsx", Description, StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(1, []))), OwnerIds, new Int32Array(0), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(4, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(3, []))), OrderStatus, OrderType, StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(5, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(6, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(7, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(8, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(9, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(10, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(11, []))), [], [], new Int32Array([]), [], [], "", "None", "None", (state.DateRangeType === 0) ? defaultOf() : MultiDateRangeTypeModule_toInt(state.DateRangeType), "", "", (matchValue_4 = state.DateStart, (matchValue_4 != null) ? matchValue_4 : defaultOf()), (matchValue_5 = state.DateEnd, (matchValue_5 != null) ? matchValue_5 : defaultOf()), state.Page);
}

function hasFilters(state) {
    const request = buildReportRequest(state);
    if ((((((((((((((state.ShowProjectSearch && !(request.ProjectIds.length === 0)) ? true : !(request.ProjectIds.length === 0)) ? true : !(request.OwnerIds.length === 0)) ? true : !(request.WarehouseIds.length === 0)) ? true : !(request.MaterialIds.length === 0)) ? true : !(request.ExternalASNIds.length === 0)) ? true : !(request.WMSReceiptIds.length === 0)) ? true : !(request.WaybillIds.length === 0)) ? true : !(request.CartonIds.length === 0)) ? true : !(request.PalletIds.length === 0)) ? true : !(request.AWSSKUIds.length === 0)) ? true : !(request.SupplierSKUIds.length === 0)) ? true : !(request.OrderStatus.length === 0)) ? true : !(request.OrderType.length === 0)) {
        return true;
    }
    else {
        return !equals(request.DateType, defaultOf());
    }
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function convertStateToTypeAheadSearch(typeHeldState) {
    return TypeStateHeldModule_toTypeAheadSearchState(typeHeldState);
}

export function init(user) {
    const patternInput = init_1(new SearchFilterKey(1, []), (searchCriteria) => getProjects(user, searchCriteria));
    const patternInput_1 = init_1(new SearchFilterKey(2, []), (searchCriteria_1) => getOwners(user, searchCriteria_1));
    const patternInput_2 = init_1(new SearchFilterKey(4, []), (searchCriteria_2) => getWarehouses(user, searchCriteria_2));
    const patternInput_3 = init_1(new SearchFilterKey(3, []), (searchCriteria_3) => getMaterials(user, searchCriteria_3));
    const patternInput_4 = init_2(toString(new SearchFilterKey(5, [])), 2);
    const patternInput_5 = init_2(toString(new SearchFilterKey(6, [])), 16);
    const patternInput_6 = init_2(toString(new SearchFilterKey(7, [])), 11);
    const patternInput_7 = init_2(toString(new SearchFilterKey(8, [])), 4);
    const patternInput_8 = init_2(toString(new SearchFilterKey(9, [])), 7);
    const patternInput_9 = init_1(new SearchFilterKey(10, []), (searchCriteria_4) => getAWSSKUs(user, searchCriteria_4));
    const patternInput_10 = init_2(toString(new SearchFilterKey(11, [])), 13);
    const patternInput_11 = init_3(new OrderTypeInboundOrOutbound(0, []));
    const patternInput_12 = init_4(toString(new SearchFilterKey(21, [])), true);
    const dateFilterState = patternInput_12[0];
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(1, []), patternInput[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(2, []), patternInput_1[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(4, []), patternInput_2[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(3, []), patternInput_3[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(6, []), patternInput_5[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(10, []), patternInput_9[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(11, []), patternInput_10[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(7, []), patternInput_6[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(8, []), patternInput_7[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(9, []), patternInput_8[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(5, []), patternInput_4[0])));
    const patternInput_13 = init_5("Export");
    return [new State(new Deferred$1(0, []), user, new Deferred$1(0, []), ofArray(selectFilters.slice()), void 0, void 0, void 0, dateFilterState, dateFilterState.DateRangeTypeSelection, dateFilterState.DateStart, dateFilterState.DateEnd, patternInput_11[0], patternInput_13[0], userHasClaim(user, 16), 1), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(2, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(2, [Item_3])), patternInput_3[1]), Cmd_map((Item_4) => (new Msg(6, [Item_4])), patternInput_12[1]), Cmd_map((Item_5) => (new Msg(7, [Item_5])), patternInput_11[1]), Cmd_map((Item_6) => (new Msg(2, [Item_6])), patternInput_9[1]), Cmd_map((Item_7) => (new Msg(2, [Item_7])), patternInput_10[1]), patternInput_13[1]]))];
}

export function update(msg, state) {
    let input_3, filterKey, existingFilter, multiSearchState, searchState, ownerIds_1, pSearchProjectState, array_7, newSelectFilters_2;
    switch (msg.tag) {
        case 13:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page - 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Alert_error(Alert_title("Inbound Details Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [new State(new Deferred$1(0, []), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getInboundDetails, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(2, [void 0]), state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Submitted")))];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(1, []), state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportInboundDetails, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 3: {
            const filterId = extractName(msg.fields[0]);
            const typeAheadFilter = StateSelectFilterHelper_extractTypeAheadFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (typeAheadFilter != null) {
                const patternInput = update_1(msg.fields[0], typeAheadFilter);
                const componentIntent = patternInput[2];
                const componentCmd = patternInput[1];
                const command_4 = (componentIntent.tag === 1) ? Cmd_map((Item_5) => (new Msg(3, [Item_5])), componentCmd) : ((componentIntent.tag === 2) ? Cmd_map((Item_5) => (new Msg(3, [Item_5])), componentCmd) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(3, [Item_4])), componentCmd), singleton((dispatch_2) => {
                    dispatch_2(new Msg(5, [componentIntent.fields[0]]));
                })])));
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_7) => {
                    if (toString(x_7.Id) === filterId) {
                        return new StateSelectFilter(x_7.Id, new TypeStateHeld(1, [patternInput[0]]), x_7.SelectedItems);
                    }
                    else {
                        return x_7;
                    }
                }, state.SelectFilters), state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), command_4];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 2: {
            const filterId_1 = extractName_1(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId_1));
            if (multiSearchFilter != null) {
                const patternInput_1 = update_2(msg.fields[0], multiSearchFilter);
                const componentIntent_1 = patternInput_1[2];
                const componentCmd_1 = patternInput_1[1];
                let command_5;
                if (componentIntent_1.tag === 1) {
                    command_5 = Cmd_map((Item_8) => (new Msg(2, [Item_8])), componentCmd_1);
                }
                else {
                    const changedData_1 = componentIntent_1.fields[0];
                    const matchValue_1 = filterId_1 === toString(new SearchFilterKey(2, []));
                    command_5 = (matchValue_1 ? Cmd_batch(ofArray([Cmd_map((Item_7) => (new Msg(2, [Item_7])), componentCmd_1), singleton((dispatch_4) => {
                        dispatch_4(new Msg(4, [changedData_1]));
                    }), singleton((dispatch_5) => {
                        dispatch_5(new Msg(11, []));
                    })])) : Cmd_batch(ofArray([Cmd_map((Item_6) => (new Msg(2, [Item_6])), componentCmd_1), singleton((dispatch_3) => {
                        dispatch_3(new Msg(4, [changedData_1]));
                    })])));
                }
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_9) => {
                    if (toString(x_9.Id) === filterId_1) {
                        return new StateSelectFilter(x_9.Id, new TypeStateHeld(0, [patternInput_1[0]]), x_9.SelectedItems);
                    }
                    else {
                        return x_9;
                    }
                }, state.SelectFilters), state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), command_5];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 11:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, (filterKey = (new SearchFilterKey(1, [])), (existingFilter = StateSelectFilterHelper_tryGet(state.SelectFilters, toString(filterKey)), (existingFilter != null) ? ((multiSearchState = convertStateToMultiSearch(existingFilter.State), (filterKey.tag === 1) ? ((searchState = init_1(filterKey, (searchCriteria) => singleton_1.Delay(() => {
                let array_1;
                const ownerIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
                return singleton_1.Return(new FSharpResult$2(0, [new DataSource(toString(new SearchFilterKey(1, [])), false, where((x_3) => (x_3.DisplayValue.toLocaleLowerCase().indexOf(searchCriteria.toLocaleLowerCase()) >= 0), sortBy((x_1) => x_1.DisplayValue, map_1((item_2) => (new DataSourceItem(int32ToString(item_2.Id), (item_2.Name === defaultOf()) ? "" : item_2.Name, item_2)), (array_1 = state.CurrentUser.Projects.filter((item) => !((item.Name === "") ? true : (item.Name === defaultOf()))), array_1.filter((item_1) => contains(item_1.OwnerId, ownerIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: numberHash,
                })))), {
                    Compare: comparePrimitives,
                })))]));
            }))[0], (ownerIds_1 = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, []))), (pSearchProjectState = (new State_3(searchState.Name, searchState.Disabled, searchState.Search, new Deferred$1(2, [new FSharpResult$2(0, [new DataSource("", false, sortBy((x_12) => x_12.DisplayValue, map_1((item_5) => (new DataSourceItem(int32ToString(item_5.Id), (item_5.Name === defaultOf()) ? "" : item_5.Name, item_5)), (array_7 = state.CurrentUser.Projects.filter((item_3) => contains(item_3.OwnerId, ownerIds_1, {
                Equals: (x_11, y_2) => (x_11 === y_2),
                GetHashCode: numberHash,
            })), array_7.filter((item_4) => !((item_4.Name === "") ? true : (item_4.Name === defaultOf()))))), {
                Compare: comparePrimitives,
            }))])]), searchState.IsModalActive, searchState.SearchCriteria, empty())), (newSelectFilters_2 = [], (void map((item_7) => {
                void (newSelectFilters_2.push(item_7));
            }, filter_3((item_6) => !equals(item_6.Id, filterKey), state.SelectFilters)), (void (newSelectFilters_2.push(StateSelectFilterModule_addMultiSearchFilter(filterKey, pSearchProjectState))), ofArray(newSelectFilters_2.slice())))))))) : (() => {
                throw new Error("Match failure");
            })())) : state.SelectFilters)), state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_none()];
        case 4:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_14) => {
                if (toString(x_14.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_14.Id, x_14.State, msg.fields[0].Items);
                }
                else {
                    return x_14;
                }
            }, state.SelectFilters), state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, isEmpty(msg.fields[0].Items) ? state.Page : 1), Cmd_none()];
        case 5:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_15) => {
                if (toString(x_15.Id) === msg.fields[0].SearchDataId) {
                    return new StateSelectFilter(x_15.Id, x_15.State, ofArray(msg.fields[0].Data));
                }
                else {
                    return x_15;
                }
            }, state.SelectFilters), state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, isEmpty(ofArray(msg.fields[0].Data)) ? state.Page : 1), Cmd_none()];
        case 10:
            if (!hasFilters(state)) {
                return [state, Toast_info(Toast_title("Invalid Option", Toast_text("Cannot export a report without filters")))];
            }
            else {
                const patternInput_3 = update_3(msg.fields[0], state.ExportReportState);
                const componentState_2 = patternInput_3[0];
                const componentIntent_2 = patternInput_3[2];
                switch (componentIntent_2.tag) {
                    case 1:
                        return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, componentState_2, state.ShowProjectSearch, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Export Cancelled")))];
                    case 2:
                        return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, componentState_2, state.ShowProjectSearch, state.Page), Cmd_none()];
                    default:
                        return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, componentState_2, state.ShowProjectSearch, state.Page), singleton((dispatch_6) => {
                            dispatch_6(new Msg(1, [new AsyncMsg$1(0, [])]));
                        })];
                }
            }
        case 6: {
            const patternInput_4 = update_4(msg.fields[0], state.DateFilter);
            const componentState_3 = patternInput_4[0];
            const componentIntent_3 = patternInput_4[2];
            if (componentIntent_3.tag === 1) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, componentState_3, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_none()];
            }
            else {
                const data_1 = componentIntent_3.fields[0];
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, componentState_3, data_1.DateRangeType, data_1.DateStart, data_1.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_none()];
            }
        }
        case 7: {
            const patternInput_5 = update_5(msg.fields[0], state.OrderTypeStatus);
            const componentIntent_4 = patternInput_5[2];
            const componentCmd_4 = patternInput_5[1];
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, patternInput_5[0], state.ExportReportState, state.ShowProjectSearch, state.Page), (componentIntent_4.tag === 1) ? Cmd_batch(ofArray([Cmd_map((Item_10) => (new Msg(7, [Item_10])), componentCmd_4), singleton((dispatch_8) => {
                dispatch_8(new Msg(9, [componentIntent_4.fields[0]]));
            })])) : ((componentIntent_4.tag === 2) ? Cmd_map((Item_11) => (new Msg(7, [Item_11])), componentCmd_4) : Cmd_batch(ofArray([Cmd_map((Item_9) => (new Msg(7, [Item_9])), componentCmd_4), singleton((dispatch_7) => {
                dispatch_7(new Msg(8, [componentIntent_4.fields[0]]));
            })])))];
        }
        case 8:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, msg.fields[0], state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, 1), Cmd_none()];
        case 9:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, msg.fields[0], state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, 1), Cmd_none()];
        default:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.SelectedOrderStatus, state.SelectedOrderType, state.SelectedSupplier, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.ShowProjectSearch, state.Page + 1), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function renderTop(state, dispatch) {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([]),
    });
}

export function renderRow1(state, dispatch) {
    let elems_12, elems_11, elems_10;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_10 = toList(delay(() => {
        let elems;
        return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
            dispatch(new Msg(2, [arg]));
        }), new SearchFilterKey(2, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(state.ShowProjectSearch ? singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_1(convertStateToMultiSearch(filter_1.State), (arg_1) => {
                dispatch(new Msg(2, [arg_1]));
            }), new SearchFilterKey(1, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => {
                let elems_2;
                return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_1(convertStateToMultiSearch(filter_2.State), (arg_2) => {
                    dispatch(new Msg(2, [arg_2]));
                }), new SearchFilterKey(4, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_9, elems_8, elems_4, elems_3, elems_6, elems_5, elems_7;
                    return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_8 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : !hasFilters(state)], ["onClick", (_arg) => {
                        dispatch(new Msg(13, []));
                    }], (elems_4 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_3 = [createElement("span", {
                        children: ["navigate_before"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("span", {
                        className: join(" ", ["is-sr-only"]),
                        children: "Previous",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : !hasFilters(state)], ["onClick", (_arg_1) => {
                        dispatch(new Msg(12, []));
                    }], (elems_6 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_5 = [createElement("span", {
                        children: ["navigate_next"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("span", {
                        className: join(" ", ["is-sr-only"]),
                        children: "Next",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(singleton((elems_7 = [render_2(state.ExportReportState, (arg_3) => {
                        dispatch(new Msg(10, [arg_3]));
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))));
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])));
}

export function renderRow2(state, dispatch) {
    let elems_5, elems_4, elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
        dispatch(new Msg(2, [arg]));
    }), new SearchFilterKey(3, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderTypeAheadSearch(state.SelectFilters, (filter_1) => render_3(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(3, [arg_1]));
    }), new SearchFilterKey(5, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_3(convertStateToTypeAheadSearch(filter_2.State), (arg_2) => {
        dispatch(new Msg(3, [arg_2]));
    }), new SearchFilterKey(6, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function renderRow3(state, dispatch) {
    let elems_5, elems_4, elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_3(convertStateToTypeAheadSearch(filter.State), (arg) => {
        dispatch(new Msg(3, [arg]));
    }), new SearchFilterKey(7, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderTypeAheadSearch(state.SelectFilters, (filter_1) => render_3(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(3, [arg_1]));
    }), new SearchFilterKey(8, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_3(convertStateToTypeAheadSearch(filter_2.State), (arg_2) => {
        dispatch(new Msg(3, [arg_2]));
    }), new SearchFilterKey(9, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function renderRow4(state, dispatch) {
    let elems_4, elems_3, elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems = [render_4(state.DateFilter, (arg) => {
        dispatch(new Msg(6, [arg]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [render_5(state.OrderTypeStatus, (arg_1) => {
        dispatch(new Msg(7, [arg_1]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function renderRow5(state, dispatch) {
    let elems_3, elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_3(convertStateToTypeAheadSearch(filter.State), (arg) => {
        dispatch(new Msg(3, [arg]));
    }), new SearchFilterKey(11, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function renderRunReport(state, dispatch) {
    let elems_4, elems_3, elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_4 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-primary", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    }], (elems = [createElement("div", {
        children: "Run Report",
    }), createElement("br", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function showLoadingSpinner(state) {
    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
        return true;
    }
    else {
        return equals(state.ExportDeferred, new Deferred$1(1, []));
    }
}

export function render(state, dispatch) {
    let elems_12, elems_1, elems, elems_5, elems_2, elems_4, elems_3, matchValue_1, pagedData, elems_11;
    if (showLoadingSpinner(state)) {
        return render_6();
    }
    else {
        return createElement("div", createObj(singleton((elems_12 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["column", "has-text-right"])], (elems = [createElement("a", {
            className: join(" ", ["has-link"]),
            href: "#/scheduled-reports",
            children: "Scheduled Reports",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_5 = [createElement("div", createObj(singleton((elems_2 = [createElement("h1", {
            className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
            children: "Inbound Details Query",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems_3 = [createElement("h1", {
            className: join(" ", ["has-text-link"]),
            children: (matchValue_1 = state.PagedDataDeferred, (matchValue_1.tag === 1) ? "Searching..." : ((matchValue_1.tag === 2) ? ((pagedData = matchValue_1.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), renderRow1(state, dispatch), renderRow2(state, dispatch), renderRow3(state, dispatch), renderRow4(state, dispatch), renderRow5(state, dispatch), renderRunReport(state, dispatch), createElement("br", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_11 = toList(delay(() => {
            let elems_10, elems_9, elems_6, children, elems_8;
            const matchValue_2 = state.PagedDataDeferred;
            switch (matchValue_2.tag) {
                case 1:
                    return singleton_2(render_6());
                case 2: {
                    const pagedData_1 = matchValue_2.fields[0];
                    if (pagedData_1.TotalCount === 0) {
                        return singleton_2(createElement("div", {
                            className: join(" ", ["has-text-centered"]),
                            children: "No items were found that meet the search criteria",
                        }));
                    }
                    else {
                        const tableHeader = (header) => createElement("th", {
                            className: join(" ", ["px-3", "py-2", "has-text-link"]),
                            children: header,
                        });
                        const tableData = (text) => createElement("td", {
                            className: join(" ", ["px-3", "py-2"]),
                            children: text,
                        });
                        return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_10 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_9 = [createElement("thead", createObj(singleton((elems_6 = [(children = ofArray([tableHeader("Supplier"), tableHeader("External ASN #"), tableHeader("WMS Receipt #"), tableHeader("Supplier PO Number"), tableHeader("Mode Of Transport"), tableHeader("Carrier Name"), tableHeader("Waybill #"), tableHeader("Order Type"), tableHeader("Order Status"), tableHeader("ASN Status"), tableHeader("Date Expected"), tableHeader("Date Received"), tableHeader("External Line #"), tableHeader("Supplier ID"), tableHeader("Carton ID"), tableHeader("Pallet ID"), tableHeader("AWS SKU"), tableHeader("Supplier SKU"), tableHeader("SKU Description"), tableHeader("Hot Add Date"), tableHeader("UOM"), tableHeader("Qty Expected"), tableHeader("Qty Received"), tableHeader("Qty Delta"), tableHeader("Check-In Time"), tableHeader("COO")]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])))), createElement("tbody", createObj(singleton((elems_8 = toList(delay(() => map_2((item) => {
                            let elems_7;
                            return createElement("tr", createObj(singleton((elems_7 = [tableData(item.Supplier), tableData(item.ExternalAsnNumber), tableData(formatInt(item.WmsReceiptNumber)), tableData(item.SupplierPONumber), tableData(item.ModeOfTransport), tableData(item.CarrierName), tableData(item.WaybillNumber), tableData(item.Type), tableData(item.OrderStatus), tableData(item.AsnStatus), tableData(formatDateTime(item.DateExpected)), tableData(formatDateTime(item.DateReceived)), tableData(formatInt(item.ExternalLineNumber)), tableData(item.SupplierId), tableData(item.CartonId), tableData(item.PalletId), tableData(item.AwsSku), tableData(item.SupplierSku), tableData(item.SkuDescription), tableData(formatDateTime(item.HotAddDate)), tableData(item.Uom), tableData(formatDecimal(item.QtyExpected)), tableData(formatDecimal(item.QtyReceived)), tableData(formatDecimal(item.Delta)), tableData(formatDateTime(item.CheckInTime)), tableData(item.Coo)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]))));
                        }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))));
                    }
                }
                default:
                    return singleton_2(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "Select filters to run the query",
                    }));
            }
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]))));
    }
}

