import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Convert_fromJson, Convert_serialize } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { TypeAheadSearchResponse_$reflection, TypeAheadSearchRequest_$reflection, DownloadReportRequest_$reflection, DownloadReportResponse_$reflection, OutboundSummaryReportResponse_$reflection, OutboundDetailsReportResponse_$reflection, InboundSummaryReportResponse_$reflection, InboundDetailsReportResponse_$reflection, OnHandDetailsReportResponse_$reflection, PagedDataResponse$1_$reflection, ReservationReportResponse_$reflection, ApiMessageResponseModule_mapMessages, ReportRequest_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { Blob_download, Http_overrideResponseType, Http_withTimeout, Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_content, Http_send } from "../fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { ResponseTypes, HttpMethod, BodyContent } from "../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { getIdToken } from "./AuthenticationAPI.fs.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { SimpleJson_parse, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";
import { isMatch } from "../fable_modules/fable-library.4.10.0/RegExp.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Int32.js";
import { map, empty, toArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { DataSource, DataSourceItem } from "../Models.fs.js";

export function getReservations(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/Reservations`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(ReservationReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function exportReservations(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/Reservations/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function exportMaterials(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/Materials/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function getOnHandDetails(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/OnHandDetails`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(OnHandDetailsReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function exportOnHandDetails(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/OnHandDetails/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function getInboundDetails(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/InboundDetails`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(InboundDetailsReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function exportInboundDetails(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/InboundDetails/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function getInboundSummary(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/InboundSummary`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(InboundSummaryReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function exportInboundSummary(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/InboundSummary/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function getOutboundDetails(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/OutboundDetails`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(OutboundDetailsReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function exportOutboundDetails(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/OutboundDetails/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function getOutboundSummary(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/OutboundSummary`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(OutboundSummaryReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function exportOutboundSummary(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/OutboundSummary/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function getDownloadableReports(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/DownloadableReports`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(DownloadReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function downloadReport(model) {
    return singleton.Delay(() => {
        let req_5, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(DownloadReportRequest_$reflection()));
        return singleton.Bind(Http_send((req_5 = Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Report/DownloadReport`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_5))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const matchValue = response.content;
                if (matchValue.tag === 1) {
                    const ResultValue = Blob_download(matchValue.fields[0], model.DisplayName);
                    result = (new FSharpResult$2(0, [void 0]));
                }
                else {
                    result = (new FSharpResult$2(1, [ApiMessageResponseModule_mapMessages(["Failed to download the report"])]));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function searchTypeAhead(model) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let req_4, req_2, req_1;
        let isValidInput;
        const matchValue = model.FilterType | 0;
        switch (matchValue) {
            case 2:
            case 3: {
                isValidInput = isMatch(/^[A-Za-z0-9 \-\+:\._\*\/\(\)]*$/gu, model.SearchParameters);
                break;
            }
            case 4:
            case 5:
            case 6: {
                isValidInput = isMatch(/^[A-Za-z0-9_\-\\\/#\$%\(\)\*,\.\+\[\]!;\| ]*$/gu, model.SearchParameters);
                break;
            }
            case 7:
            case 8:
            case 9: {
                isValidInput = isMatch(/^[A-Za-z0-9 \-=\/\.#!\$#\(\)\.%,&\[\]\*:;\?@<\\^_`~\+]*$/gu, model.SearchParameters);
                break;
            }
            case 10:
            case 23: {
                isValidInput = isMatch(/^[A-Za-z0-9_\-\\\/ \|\(\),]*$/gu, model.SearchParameters);
                break;
            }
            case 11:
            case 12: {
                isValidInput = isMatch(/^[A-Za-z0-9-\+\/#|!_\\\[\]\.|& ]*$/gu, model.SearchParameters);
                break;
            }
            case 13:
            case 15: {
                isValidInput = isMatch(/^[A-Za-z0-9^\-\+\/#|!_\\\[\]\(\)\*\.|& ]*$/gu, model.SearchParameters);
                break;
            }
            case 16:
            case 17:
            case 20:
            case 21: {
                if (isMatch(/^[0-9]*$/gu, model.SearchParameters)) {
                    try {
                        parse(model.SearchParameters, 511, false, 32);
                        isValidInput = true;
                    }
                    catch (exn) {
                        console.log(some(exn.message));
                        isValidInput = false;
                    }
                }
                else {
                    isValidInput = false;
                }
                break;
            }
            case 18:
            case 19: {
                isValidInput = isMatch(/([0-9]-)*/gu, model.SearchParameters);
                break;
            }
            case 22: {
                isValidInput = isMatch(/^[A-Za-z0-9 \-\+:\._\*\/\(\)]*$/gu, model.SearchParameters);
                break;
            }
            default:
                isValidInput = true;
        }
        if (isValidInput) {
            const json = Convert_serialize(model, createTypeInfo(TypeAheadSearchRequest_$reflection()));
            return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Search/TypeAhead`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
                const response = _arg;
                if (response.statusCode === 200) {
                    let typeAheadSearchResponses;
                    const response_2 = response;
                    const toGeneralError = (error) => {
                        console.error(some("API error"), error);
                        return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                    };
                    if (isNullOrWhiteSpace(response_2.responseText)) {
                        typeAheadSearchResponses = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                    }
                    else {
                        let input_4;
                        const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                        try {
                            input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(TypeAheadSearchResponse_$reflection()))]));
                        }
                        catch (ex) {
                            input_4 = (new FSharpResult$2(1, [ex.message]));
                        }
                        typeAheadSearchResponses = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                    }
                    const result = new DataSource(model.FilterName, false, toArray((typeAheadSearchResponses.tag === 1) ? empty() : map((x_1) => (new DataSourceItem(x_1.Id, x_1.Value, x_1.Value)), typeAheadSearchResponses.fields[0].Data)));
                    return singleton.Return(new FSharpResult$2(0, [result]));
                }
                else {
                    return singleton.Return(tryParseAsApiMessage(response));
                }
            });
        }
        else {
            return singleton.Return(new FSharpResult$2(0, [new DataSource(model.FilterName, false, [])]));
        }
    }), (_arg_2) => {
        console.log(some(_arg_2.message));
        return singleton.Return(new FSharpResult$2(0, [new DataSource(model.FilterName, false, [])]));
    }));
}

