import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ReportRequest, ApiMessageResponse_$reflection, UserResponse_$reflection, PagedDataResponse$1_$reflection, ReservationReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, int32_type, bool_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { StateSelectFilterHelper_renderSearch, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilterModule_addMultiSearchFilter, TypeStateHeldModule_toMultiSearchState, StateSelectFilterHelper_selectedItemsIds, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { render as render_2, State as State_2, update as update_1, extractName, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_2, State_$reflection as State_$reflection_1 } from "../Components/MultiSelectSearch.fs.js";
import { DataSource, DataSourceItem, Default_pagedData, SearchFilterKey, DataSourceItem_$reflection } from "../Models.fs.js";
import { render as render_3, update as update_2, init as init_2, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_2 } from "../Components/ExportReportDialog.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { createObj, comparePrimitives, numberHash, int32ToString, equals, defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getMaterials, getWarehouses, getAccounts, getProjects, getOwners } from "../API/DataSourceAPI.fs.js";
import { isEmpty, filter as filter_5, map, singleton, empty, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { userHasClaim } from "../Components/ClaimHelpers.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_text, Toast_title, Toast_info, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportReservations, getReservations } from "../API/ReportAPI.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { contains, map as map_1, sortBy, where } from "../fable_modules/fable-library.4.10.0/Array.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { map as map_2, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { formatDecimal } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(PagedDataDeferred, CurrentUser, ExportDeferred, SelectFilters, ProjectSearch, SelectedProjects, AccountSearch, SelectedAccounts, WarehouseSearch, SelectedWarehouses, MaterialSearch, SelectedMaterials, ExportReportState, ShowProjectSearch, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.CurrentUser = CurrentUser;
        this.ExportDeferred = ExportDeferred;
        this.SelectFilters = SelectFilters;
        this.ProjectSearch = ProjectSearch;
        this.SelectedProjects = SelectedProjects;
        this.AccountSearch = AccountSearch;
        this.SelectedAccounts = SelectedAccounts;
        this.WarehouseSearch = WarehouseSearch;
        this.SelectedWarehouses = SelectedWarehouses;
        this.MaterialSearch = MaterialSearch;
        this.SelectedMaterials = SelectedMaterials;
        this.ExportReportState = ExportReportState;
        this.ShowProjectSearch = ShowProjectSearch;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.ReservationsQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(ReservationReportResponse_$reflection()))], ["CurrentUser", UserResponse_$reflection()], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["ProjectSearch", State_$reflection_1()], ["SelectedProjects", list_type(DataSourceItem_$reflection())], ["AccountSearch", State_$reflection_1()], ["SelectedAccounts", list_type(DataSourceItem_$reflection())], ["WarehouseSearch", State_$reflection_1()], ["SelectedWarehouses", list_type(DataSourceItem_$reflection())], ["MaterialSearch", State_$reflection_1()], ["SelectedMaterials", list_type(DataSourceItem_$reflection())], ["ExportReportState", State_$reflection_2()], ["ShowProjectSearch", bool_type], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "ExportReportMsg", "RebuildProjectFilters", "MultiSelectSearchMsg", "SetMultiSearchItems", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.ReservationsQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ReservationReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ReservationReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [], [["Item", Msg_$reflection_2()]], [["Item", SelectedItemsChangedData_$reflection()]], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    let Description;
    const matchValue = state.ExportReportState.ReportDescription;
    Description = ((matchValue != null) ? matchValue : "");
    const ProjectIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(1, [])));
    const OwnerIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
    const AccountIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(0, [])));
    const MaterialIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(3, [])));
    return new ReportRequest("Reservations.xlsx", Description, ProjectIds, OwnerIds, AccountIds, StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(4, []))), MaterialIds, new Int32Array([]), new Int32Array([]), [], new Int32Array([]), [], [], [], [], [], [], [], new Int32Array([]), [], [], "", "None", "None", defaultOf(), "", "", defaultOf(), defaultOf(), state.Page);
}

function hasFilters(state) {
    const request = buildReportRequest(state);
    if (((state.ShowProjectSearch && !(request.ProjectIds.length === 0)) ? true : !(request.AccountIds.length === 0)) ? true : !(request.WarehouseIds.length === 0)) {
        return true;
    }
    else {
        return !(request.MaterialIds.length === 0);
    }
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function init(user) {
    const patternInput = init_1(new SearchFilterKey(2, []), (searchCriteria) => getOwners(user, searchCriteria));
    const patternInput_1 = init_1(new SearchFilterKey(1, []), (searchCriteria_1) => getProjects(user, searchCriteria_1));
    const projectSearchState = patternInput_1[0];
    const patternInput_2 = init_1(new SearchFilterKey(0, []), (searchCriteria_2) => getAccounts(user, searchCriteria_2));
    const accountSearchState = patternInput_2[0];
    const patternInput_3 = init_1(new SearchFilterKey(4, []), (searchCriteria_3) => getWarehouses(user, searchCriteria_3));
    const warehouseSearchState = patternInput_3[0];
    const patternInput_4 = init_1(new SearchFilterKey(3, []), (searchCriteria_4) => getMaterials(user, searchCriteria_4));
    const materialSearchState = patternInput_4[0];
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(1, []), projectSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(2, []), patternInput[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(0, []), accountSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(4, []), warehouseSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(3, []), materialSearchState)));
    const patternInput_5 = init_2("Export");
    return [new State(new Deferred$1(0, []), user, new Deferred$1(0, []), ofArray(selectFilters.slice()), projectSearchState, empty(), accountSearchState, empty(), warehouseSearchState, empty(), materialSearchState, empty(), patternInput_5[0], userHasClaim(user, 16), 1), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(4, [Item])), patternInput_1[1]), Cmd_map((Item_1) => (new Msg(4, [Item_1])), patternInput[1]), Cmd_map((Item_2) => (new Msg(4, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(4, [Item_3])), patternInput_3[1]), Cmd_map((Item_4) => (new Msg(4, [Item_4])), patternInput_4[1]), patternInput_5[1]]))];
}

export function update(msg, state) {
    let input_3, filterKey, existingFilter, multiSearchState, searchState, ownerIds_1, pSearchProjectState, array_7, newSelectFilters_1;
    switch (msg.tag) {
        case 7:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page - 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Alert_error(Alert_title("Reservations Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [new State(new Deferred$1(0, []), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getReservations, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(2, [void 0]), state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Submitted")))];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(1, []), state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportReservations, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 4: {
            const filterId = extractName(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (multiSearchFilter != null) {
                const patternInput = update_1(msg.fields[0], multiSearchFilter);
                const componentIntent = patternInput[2];
                const componentCmd = patternInput[1];
                let command_4;
                if (componentIntent.tag === 1) {
                    command_4 = Cmd_map((Item_6) => (new Msg(4, [Item_6])), componentCmd);
                }
                else {
                    const changedData = componentIntent.fields[0];
                    const matchValue_1 = filterId === toString(new SearchFilterKey(2, []));
                    command_4 = (matchValue_1 ? Cmd_batch(ofArray([Cmd_map((Item_5) => (new Msg(4, [Item_5])), componentCmd), singleton((dispatch_3) => {
                        dispatch_3(new Msg(5, [changedData]));
                    }), singleton((dispatch_4) => {
                        dispatch_4(new Msg(3, []));
                    })])) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(4, [Item_4])), componentCmd), singleton((dispatch_2) => {
                        dispatch_2(new Msg(5, [changedData]));
                    })])));
                }
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_7) => {
                    if (toString(x_7.Id) === filterId) {
                        return new StateSelectFilter(x_7.Id, new TypeStateHeld(0, [patternInput[0]]), x_7.SelectedItems);
                    }
                    else {
                        return x_7;
                    }
                }, state.SelectFilters), state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page), command_4];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, (filterKey = (new SearchFilterKey(1, [])), (existingFilter = StateSelectFilterHelper_tryGet(state.SelectFilters, toString(filterKey)), (existingFilter != null) ? ((multiSearchState = convertStateToMultiSearch(existingFilter.State), (filterKey.tag === 1) ? ((searchState = init_1(filterKey, (searchCriteria) => singleton_1.Delay(() => {
                let array_1;
                const ownerIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
                return singleton_1.Return(new FSharpResult$2(0, [new DataSource(toString(new SearchFilterKey(1, [])), false, where((x_3) => (x_3.DisplayValue.toLocaleLowerCase().indexOf(searchCriteria.toLocaleLowerCase()) >= 0), sortBy((x_1) => x_1.DisplayValue, map_1((item_2) => (new DataSourceItem(int32ToString(item_2.Id), (item_2.Name === defaultOf()) ? "" : item_2.Name, item_2)), (array_1 = state.CurrentUser.Projects.filter((item) => !((item.Name === "") ? true : (item.Name === defaultOf()))), array_1.filter((item_1) => contains(item_1.OwnerId, ownerIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: numberHash,
                })))), {
                    Compare: comparePrimitives,
                })))]));
            }))[0], (ownerIds_1 = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, []))), (pSearchProjectState = (new State_2(searchState.Name, searchState.Disabled, searchState.Search, new Deferred$1(2, [new FSharpResult$2(0, [new DataSource("", false, sortBy((x_10) => x_10.DisplayValue, map_1((item_5) => (new DataSourceItem(int32ToString(item_5.Id), (item_5.Name === defaultOf()) ? "" : item_5.Name, item_5)), (array_7 = state.CurrentUser.Projects.filter((item_3) => contains(item_3.OwnerId, ownerIds_1, {
                Equals: (x_9, y_2) => (x_9 === y_2),
                GetHashCode: numberHash,
            })), array_7.filter((item_4) => !((item_4.Name === "") ? true : (item_4.Name === defaultOf()))))), {
                Compare: comparePrimitives,
            }))])]), searchState.IsModalActive, searchState.SearchCriteria, empty())), (newSelectFilters_1 = [], (void map((item_7) => {
                void (newSelectFilters_1.push(item_7));
            }, filter_5((item_6) => !equals(item_6.Id, filterKey), state.SelectFilters)), (void (newSelectFilters_1.push(StateSelectFilterModule_addMultiSearchFilter(filterKey, pSearchProjectState))), ofArray(newSelectFilters_1.slice())))))))) : (() => {
                throw new Error("Match failure");
            })())) : state.SelectFilters)), state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page), Cmd_none()];
        case 5:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_12) => {
                if (toString(x_12.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_12.Id, x_12.State, msg.fields[0].Items);
                }
                else {
                    return x_12;
                }
            }, state.SelectFilters), state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, isEmpty(msg.fields[0].Items) ? state.Page : 1), Cmd_none()];
        case 2:
            if (!hasFilters(state)) {
                return [state, Toast_info(Toast_title("Invalid Option", Toast_text("Cannot export a report without filters")))];
            }
            else {
                const patternInput_2 = update_2(msg.fields[0], state.ExportReportState);
                const componentState_1 = patternInput_2[0];
                const componentIntent_1 = patternInput_2[2];
                switch (componentIntent_1.tag) {
                    case 1:
                        return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, componentState_1, state.ShowProjectSearch, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Export Cancelled")))];
                    case 2:
                        return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, componentState_1, state.ShowProjectSearch, state.Page), Cmd_none()];
                    default:
                        return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, componentState_1, state.ShowProjectSearch, state.Page), singleton((dispatch_5) => {
                            dispatch_5(new Msg(1, [new AsyncMsg$1(0, [])]));
                        })];
                }
            }
        default:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Page + 1), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function renderRunReport(state, dispatch) {
    let elems_4, elems_3, elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_4 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-primary", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    }], (elems = [createElement("div", {
        children: "Run Report",
    }), createElement("br", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function showLoadingSpinner(state) {
    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
        return true;
    }
    else {
        return equals(state.ExportDeferred, new Deferred$1(1, []));
    }
}

export function render(state, dispatch) {
    let elems_26, elems_1, elems, elems_19, elems_3, elems_2, matchValue_1, pagedData, elems_18, elems_17, elems_16, elems_25;
    if (showLoadingSpinner(state)) {
        return render_1();
    }
    else {
        return createElement("div", createObj(singleton((elems_26 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["column", "has-text-right"])], (elems = [createElement("a", {
            className: join(" ", ["has-link"]),
            href: "#/scheduled-reports",
            children: "Scheduled Reports",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_19 = [createElement("h1", {
            className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
            children: "Reservations Query",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems_2 = [createElement("h1", {
            className: join(" ", ["has-text-link"]),
            children: (matchValue_1 = state.PagedDataDeferred, (matchValue_1.tag === 1) ? "Searching..." : ((matchValue_1.tag === 2) ? ((pagedData = matchValue_1.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_16 = toList(delay(() => {
            let elems_4;
            return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_2(convertStateToMultiSearch(filter.State), (arg) => {
                dispatch(new Msg(4, [arg]));
            }), new SearchFilterKey(2, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                let elems_5;
                return append(state.ShowProjectSearch ? singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_5 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_2(convertStateToMultiSearch(filter_1.State), (arg_1) => {
                    dispatch(new Msg(4, [arg_1]));
                }), new SearchFilterKey(1, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))) : empty_1(), delay(() => {
                    let elems_6;
                    return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_6 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_2(convertStateToMultiSearch(filter_2.State), (arg_2) => {
                        dispatch(new Msg(4, [arg_2]));
                    }), new SearchFilterKey(3, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                        let elems_7;
                        return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_7 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_3) => render_2(convertStateToMultiSearch(filter_3.State), (arg_3) => {
                            dispatch(new Msg(4, [arg_3]));
                        }), new SearchFilterKey(0, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                            let elems_8;
                            return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_8 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_4) => render_2(convertStateToMultiSearch(filter_4.State), (arg_4) => {
                                dispatch(new Msg(4, [arg_4]));
                            }), new SearchFilterKey(4, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                                let elems_15, elems_14, elems_10, elems_9, elems_12, elems_11, elems_13;
                                return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_14 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : !hasFilters(state)], ["onClick", (_arg) => {
                                    dispatch(new Msg(7, []));
                                }], (elems_10 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_9 = [createElement("i", {
                                    children: ["navigate_before"],
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("span", {
                                    className: join(" ", ["is-sr-only"]),
                                    children: "Previous",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : !hasFilters(state)], ["onClick", (_arg_1) => {
                                    dispatch(new Msg(6, []));
                                }], (elems_12 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_11 = [createElement("i", {
                                    children: ["navigate_next"],
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("span", {
                                    className: join(" ", ["is-sr-only"]),
                                    children: "Next",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", createObj(singleton((elems_13 = [render_3(state.ExportReportState, (arg_5) => {
                                    dispatch(new Msg(2, [arg_5]));
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))));
                            }));
                        }));
                    }));
                }));
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), renderRunReport(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_25 = toList(delay(() => {
            let elems_24, elems_23, elems_20, children, elems_22;
            const matchValue_2 = state.PagedDataDeferred;
            switch (matchValue_2.tag) {
                case 1:
                    return singleton_2(render_1());
                case 2: {
                    const pagedData_1 = matchValue_2.fields[0];
                    if (pagedData_1.TotalCount === 0) {
                        return singleton_2(createElement("div", {
                            className: join(" ", ["has-text-centered"]),
                            children: "No items were found that meet the search criteria",
                        }));
                    }
                    else {
                        const tableHeader = (header) => createElement("th", {
                            className: join(" ", ["px-3", "py-2", "has-text-link"]),
                            children: header,
                        });
                        const tableData = (text) => createElement("td", {
                            className: join(" ", ["px-3", "py-2"]),
                            children: text,
                        });
                        return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_24 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_23 = [createElement("thead", createObj(singleton((elems_20 = [(children = ofArray([tableHeader("Warehouse"), tableHeader("Supplier ID"), tableHeader("Supplier"), tableHeader("AWS SKU"), tableHeader("Supplier SKU"), tableHeader("SKU Description"), tableHeader("Qty"), tableHeader("Qty on Pending Orders"), tableHeader("Qty Allocated"), tableHeader("Qty Picked"), tableHeader("Qty on Hold"), tableHeader("Qty Available"), tableHeader("ODM Code")]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])))), createElement("tbody", createObj(singleton((elems_22 = toList(delay(() => map_2((item) => {
                            let elems_21;
                            return createElement("tr", createObj(singleton((elems_21 = [tableData(item.WarehouseName), tableData(item.SupplierCode), tableData(item.Supplier), tableData(item.AwsSku), tableData(item.SupplierSku), tableData(item.SkuDescription), tableData(formatDecimal(item.Quantity)), tableData(formatDecimal(item.QuantityPending)), tableData(formatDecimal(item.QuantityAllocated)), tableData(formatDecimal(item.QuantityExecuting)), tableData(formatDecimal(item.QuantityHold)), tableData(formatDecimal(item.QuantityAvailable)), tableData(item.OdmCode)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]))));
                        }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))));
                    }
                }
                default:
                    return singleton_2(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "Select filters to run the query",
                    }));
            }
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))]))));
    }
}

