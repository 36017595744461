import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, list_type, union_type, bool_type, enum_type, int32_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DataSource, DataSourceItem_$reflection, DataSource_$reflection } from "../Models.fs.js";
import { TypeAheadSearchRequest, ApiMessageResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { head, tail, isEmpty, length, where, append, tryFind, singleton, ofArray, empty, toArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { searchTypeAhead } from "../API/ReportAPI.fs.js";
import { Toast_text, Toast_title, Toast_error } from "./SweetAlert.fs.js";
import { createElement } from "react";
import { map, empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { render as render_1 } from "./Loader.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(Name, FilterType, Disabled, SearchDeferred, IsModalActive, SearchCriteria, SelectedItems) {
        super();
        this.Name = Name;
        this.FilterType = (FilterType | 0);
        this.Disabled = Disabled;
        this.SearchDeferred = SearchDeferred;
        this.IsModalActive = IsModalActive;
        this.SearchCriteria = SearchCriteria;
        this.SelectedItems = SelectedItems;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.State", [], State, () => [["Name", string_type], ["FilterType", enum_type("FootPrint.Portal.DTOs.TypeAheadFilterType", int32_type, [["Unknown", 1], ["ExternalASNNosInboundDetails", 2], ["ExternalASNNosInboundSummary", 3], ["CartonIdInboundDetails", 4], ["CartonIdOnHandDetails", 5], ["CartonIdOutboundDetails", 6], ["PalletIdInboundDetails", 7], ["PalletIdOnHandDetails", 8], ["PalletIdOutboundDetails", 9], ["TrackingIDProNoOutboundSummary", 10], ["WaybillNoInboundDetails", 11], ["WaybillNoInboundSummary", 12], ["SupplierSKUInboundDetails", 13], ["SupplierSKUOnHandDetails", 14], ["SupplierSKUOutboundDetails", 15], ["WMSReceiptNoInboundDetails", 16], ["WMSReceiptNoInboundSummary", 17], ["BuyerPONoOutboundDetails", 18], ["BuyerPONoOutboundSummary", 19], ["WMSOrderNoOutboundDetails", 20], ["WMSOrderNoOutboundSummary", 21], ["ASNReferenceOutboundDetails", 22], ["AWSPartNoOnHandDetails", 23], ["ManageRolesAccounts", 24]])], ["Disabled", bool_type], ["SearchDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))], ["IsModalActive", bool_type], ["SearchCriteria", string_type], ["SelectedItems", list_type(DataSourceItem_$reflection())]]);
}

export class SelectedItemsChangedData extends Record {
    constructor(Name, Items) {
        super();
        this.Name = Name;
        this.Items = Items;
    }
}

export function SelectedItemsChangedData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.SelectedItemsChangedData", [], SelectedItemsChangedData, () => [["Name", string_type], ["Items", list_type(DataSourceItem_$reflection())]]);
}

export function initializedSelectItemsChangedData(name, selectedItems) {
    return new DataSource("", false, toArray(selectedItems));
}

export class SetIsModalActiveData extends Record {
    constructor(Name, IsModalActive) {
        super();
        this.Name = Name;
        this.IsModalActive = IsModalActive;
    }
}

export function SetIsModalActiveData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.SetIsModalActiveData", [], SetIsModalActiveData, () => [["Name", string_type], ["IsModalActive", bool_type]]);
}

export class SetSearchCriteriaData extends Record {
    constructor(Name, SearchCriteria) {
        super();
        this.Name = Name;
        this.SearchCriteria = SearchCriteria;
    }
}

export function SetSearchCriteriaData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.SetSearchCriteriaData", [], SetSearchCriteriaData, () => [["Name", string_type], ["SearchCriteria", string_type]]);
}

export class DataSourceItemsData extends Record {
    constructor(Name, Items) {
        super();
        this.Name = Name;
        this.Items = Items;
    }
}

export function DataSourceItemsData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.DataSourceItemsData", [], DataSourceItemsData, () => [["Name", string_type], ["Items", list_type(DataSourceItem_$reflection())]]);
}

export class DataSourceItemData extends Record {
    constructor(Name, Item) {
        super();
        this.Name = Name;
        this.Item = Item;
    }
}

export function DataSourceItemData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.DataSourceItemData", [], DataSourceItemData, () => [["Name", string_type], ["Item", DataSourceItem_$reflection()]]);
}

export class AsyncStartedData extends Record {
    constructor(Name, Item) {
        super();
        this.Name = Name;
        this.Item = Item;
    }
}

export function AsyncStartedData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.AsyncStartedData", [], AsyncStartedData, () => [["Name", string_type], ["Item", DataSourceItem_$reflection()]]);
}

export class AsyncCompletedData extends Record {
    constructor(Name, Item) {
        super();
        this.Name = Name;
        this.Item = Item;
    }
}

export function AsyncCompletedData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.AsyncCompletedData", [], AsyncCompletedData, () => [["Name", string_type], ["Item", DataSourceItem_$reflection()]]);
}

export class NoData extends Record {
    constructor(Name) {
        super();
        this.Name = Name;
    }
}

export function NoData_$reflection() {
    return record_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.NoData", [], NoData, () => [["Name", string_type]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectedItemsChanged", "Cancel", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.Intent", [], Intent, () => [[["Item", DataSource_$reflection()]], [], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsModalActive", "SetSearchCriteria", "AddSelectedItem", "RemoveSelectedItem", "SelectAll", "ClearSelectedItems", "CancelPressed", "SearchAsync"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.TypeAheadSearch.TypeAheadSearch.Msg", [], Msg, () => [[["Item", SetIsModalActiveData_$reflection()]], [["Item", SetSearchCriteriaData_$reflection()]], [["Item", DataSourceItemData_$reflection()]], [["Item", DataSourceItemData_$reflection()]], [["Item", NoData_$reflection()]], [["Item", NoData_$reflection()]], [["Item", NoData_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataSource_$reflection(), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", DataSource_$reflection()]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]]]);
}

export function isPaginated(state) {
    const matchValue = state.SearchDeferred;
    let matchResult, pagedData;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            matchResult = 0;
            pagedData = matchValue.fields[0].fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return pagedData.IsPaginated;
        default:
            return false;
    }
}

export function extractName(componentMsg) {
    switch (componentMsg.tag) {
        case 0:
            return componentMsg.fields[0].Name;
        case 1:
            return componentMsg.fields[0].Name;
        case 2:
            return componentMsg.fields[0].Name;
        case 3:
            return componentMsg.fields[0].Name;
        case 4:
            return componentMsg.fields[0].Name;
        case 5:
            return componentMsg.fields[0].Name;
        case 6:
            return componentMsg.fields[0].Name;
        default:
            switch (componentMsg.fields[0].tag) {
                case 0:
                    return "";
                case 2:
                    if (componentMsg.fields[0].fields[0].tag === 1) {
                        return "";
                    }
                    else {
                        return componentMsg.fields[0].fields[0].fields[0].SearchDataId;
                    }
                default:
                    return componentMsg.fields[0].fields[0];
            }
    }
}

export function init(filterName, filterType) {
    return [new State(filterName, filterType, false, new Deferred$1(0, []), false, "", empty()), Cmd_none()];
}

export function update(msg, state) {
    let msg_1;
    switch (msg.tag) {
        case 4: {
            const matchValue = state.SearchDeferred;
            let matchResult, pagedData;
            if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    matchResult = 0;
                    pagedData = matchValue.fields[0].fields[0];
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.Name, state.FilterType, state.Disabled, state.SearchDeferred, state.IsModalActive, state.SearchCriteria, isPaginated(state) ? state.SelectedItems : ofArray(pagedData.Data)), Cmd_none(), new Intent(2, [])];
                default:
                    return [state, Cmd_none(), new Intent(2, [])];
            }
        }
        case 5:
            return [new State(state.Name, state.FilterType, state.Disabled, state.SearchDeferred, state.IsModalActive, state.SearchCriteria, empty()), (msg_1 = (new Msg(0, [new SetIsModalActiveData(state.Name, false)])), singleton((dispatch) => {
                dispatch(msg_1);
            })), new Intent(2, [])];
        case 6:
            return [state, Cmd_none(), new Intent(1, [])];
        case 2: {
            const matchValue_1 = tryFind((x) => (x.Id === msg.fields[0].Item.Id), state.SelectedItems);
            if (matchValue_1 == null) {
                return [new State(state.Name, state.FilterType, state.Disabled, state.SearchDeferred, state.IsModalActive, state.SearchCriteria, append(singleton(msg.fields[0].Item), state.SelectedItems)), Cmd_none(), new Intent(2, [])];
            }
            else {
                return [state, Cmd_none(), new Intent(2, [])];
            }
        }
        case 3:
            return [new State(state.Name, state.FilterType, state.Disabled, state.SearchDeferred, state.IsModalActive, state.SearchCriteria, where((x_1) => (x_1.Id !== msg.fields[0].Item.Id), state.SelectedItems)), Cmd_none(), new Intent(2, [])];
        case 0: {
            const intent = msg.fields[0].IsModalActive ? (new Intent(2, [])) : (new Intent(0, [initializedSelectItemsChangedData(state.Name, state.SelectedItems)]));
            return [new State(state.Name, state.FilterType, state.Disabled, state.SearchDeferred, msg.fields[0].IsModalActive, msg.fields[0].IsModalActive ? "" : state.SearchCriteria, state.SelectedItems), Cmd_none(), intent];
        }
        case 7:
            switch (msg.fields[0].tag) {
                case 1:
                    if (equals(state.SearchDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none(), new Intent(2, [])];
                    }
                    else if (!state.IsModalActive) {
                        return [state, Cmd_none(), new Intent(2, [])];
                    }
                    else if (isNullOrWhiteSpace(state.SearchCriteria)) {
                        return [state, Cmd_none(), new Intent(2, [])];
                    }
                    else {
                        return [new State(state.Name, state.FilterType, state.Disabled, new Deferred$1(1, []), state.IsModalActive, state.SearchCriteria, state.SelectedItems), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, searchTypeAhead, new TypeAheadSearchRequest(state.Name, state.FilterType, state.SearchCriteria), (arg) => (new Msg(7, [new AsyncMsg$1(2, [arg])]))), new Intent(2, [])];
                    }
                case 2:
                    return [new State(state.Name, state.FilterType, state.Disabled, new Deferred$1(2, [msg.fields[0].fields[0]]), state.IsModalActive, state.SearchCriteria, state.SelectedItems), (msg.fields[0].fields[0].tag === 1) ? Toast_error(Toast_title(`Search ${state.Name}`, Toast_text("Something went wrong."))) : Cmd_none(), new Intent(0, [(msg.fields[0].fields[0].tag === 1) ? (() => {
                        throw new Error(`Search ${state.Name}`);
                    })() : msg.fields[0].fields[0].fields[0]])];
                default:
                    return [state, Cmd_none(), new Intent(2, [])];
            }
        default:
            return [new State(state.Name, state.FilterType, state.Disabled, state.SearchDeferred, state.IsModalActive, msg.fields[0].SearchCriteria, state.SelectedItems), Cmd_none(), new Intent(2, [])];
    }
}

export function render(state, dispatch) {
    let elems_22, elems_21, elems_1, elems, elems_19, elems_8, elems_7, elems_6, elems_2, value_25, elems_5, elems_4, elems_3, elems_9, matchValue_1, elems_14, elems_13, elems_12, elems_18, elems_17, elems_16, elems_20, elems_27, elems_23, value_114, matchValue_3, elems_26, elems_25, elems_24;
    const modal = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("modal"), delay(() => append_1(singleton_1("px-4"), delay(() => (state.IsModalActive ? singleton_1("is-active") : empty_1()))))))))], (elems_22 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", "modal-card"], (elems_21 = [createElement("header", createObj(ofArray([["className", "modal-card-head"], (elems_1 = [createElement("p", {
        className: join(" ", ["modal-card-title", "is-size-6"]),
        children: `Select ${state.Name}`,
    }), createElement("span", createObj(ofArray([["className", join(" ", ["material-icons"])], (elems = [createElement("i", {
        children: ["check"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("section", createObj(ofArray([["className", "modal-card-body"], (elems_19 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_6 = [createElement("p", createObj(ofArray([["className", join(" ", ["control", "is-expanded"])], (elems_2 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "text"], ["placeholder", "Search..."], (value_25 = state.SearchCriteria, ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_25)) {
            e.value = value_25;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(1, [new SetSearchCriteriaData(state.Name, ev.target.value)]));
    }], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            dispatch(new Msg(7, [new AsyncMsg$1(1, [state.Name])]));
        }
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_5 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(7, [new AsyncMsg$1(1, [state.Name])]));
    }], (elems_4 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons"])], (elems_3 = [createElement("i", {
        children: ["search"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Search",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_9 = [createElement("span", {
        className: join(" ", ["column", "pt-0", "pb-0", "is-size-7"]),
        children: (matchValue_1 = state.SearchDeferred, (matchValue_1.tag === 1) ? "" : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (matchValue_1.fields[0].fields[0].IsPaginated ? (`Showing top ${matchValue_1.fields[0].fields[0].Data.length} results`) : "") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("columns"), delay(() => ((length(state.SelectedItems) === 0) ? singleton_1("is-hidden") : empty_1()))))))], (elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "is-grouped", "is-grouped-multiline"])], (elems_12 = toList(delay(() => map((item) => {
        let elems_11, elems_10;
        return createElement("div", createObj(ofArray([["className", "control"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["tags", "has-addons"])], (elems_10 = [createElement("span", {
            className: join(" ", ["tag"]),
            children: item.DisplayValue,
        }), createElement("a", {
            className: join(" ", ["tag", "is-delete"]),
            onClick: (_arg_1) => {
                dispatch(new Msg(3, [new DataSourceItemData(state.Name, item)]));
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])));
    }, state.SelectedItems))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", "table-container"], ["style", {
        maxHeight: 250,
        overflowY: "auto",
    }], (elems_18 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-bordered", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_17 = [createElement("tbody", createObj(singleton((elems_16 = toList(delay(() => {
        const matchValue_2 = state.SearchDeferred;
        return (matchValue_2.tag === 1) ? singleton_1(render_1()) : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? map((item_1) => {
            let elems_15;
            return createElement("tr", createObj(ofArray([["className", join(" ", ["is-clickable"])], (elems_15 = [createElement("td", {
                className: join(" ", ["is-size-7", "p-2"]),
                children: item_1.DisplayValue,
                onClick: (_arg_2) => {
                    dispatch(new Msg(2, [new DataSourceItemData(state.Name, item_1)]));
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])));
        }, matchValue_2.fields[0].fields[0].Data) : singleton_1(defaultOf())) : singleton_1(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("footer", createObj(ofArray([["className", join(" ", ["modal-card-foot", "is-justify-content-center"])], (elems_20 = [createElement("button", {
        className: join(" ", ["button", "is-primary", "is-small", "is-fullwidth"]),
        disabled: isEmpty(state.SelectedItems),
        type: "button",
        children: "OK",
        onClick: (_arg_3) => {
            dispatch(new Msg(0, [new SetIsModalActiveData(state.Name, false)]));
        },
    }), createElement("button", {
        className: join(" ", toList(delay(() => append_1(singleton_1("button"), delay(() => append_1(singleton_1("is-small"), delay(() => append_1(singleton_1("is-fullwidth"), delay(() => (isPaginated(state) ? singleton_1("is-hidden") : empty_1())))))))))),
        type: "button",
        children: "Select All",
        onClick: (_arg_4) => {
            dispatch(new Msg(4, [new NoData(state.Name)]));
        },
    }), createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Cancel",
        onClick: (_arg_5) => {
            dispatch(new Msg(5, [new NoData(state.Name)]));
            dispatch(new Msg(6, [new NoData(state.Name)]));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])));
    return createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_27 = [modal, createElement("p", createObj(ofArray([["className", join(" ", ["control", "is-expanded"])], (elems_23 = [createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-clickable"])], ["placeholder", `Select ${state.Name}...`], ["readOnly", true], ["disabled", state.Disabled], (value_114 = ((matchValue_3 = state.SelectedItems, !isEmpty(matchValue_3) ? (isEmpty(tail(matchValue_3)) ? head(matchValue_3).DisplayValue : (`${length(matchValue_3)} ${state.Name.toLocaleLowerCase()}s selected`)) : "")), ["ref", (e_1) => {
        if (!(e_1 == null) && !equals(e_1.value, value_114)) {
            e_1.value = value_114;
        }
    }]), ["onClick", (_arg_6) => {
        dispatch(new Msg(0, [new SetIsModalActiveData(state.Name, true)]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_26 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", state.Disabled], ["onClick", (_arg_7) => {
        dispatch(new Msg(0, [new SetIsModalActiveData(state.Name, true)]));
    }], (elems_25 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons"])], (elems_24 = [createElement("i", {
        children: ["list"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("span", {
        className: "is-sr-only",
        children: `Select ${state.Name}`,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])));
}

