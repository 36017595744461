import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Http_content, Http_withTimeout, Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_send } from "../fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { BodyContent, HttpMethod } from "../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { getIdToken } from "./AuthenticationAPI.fs.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { CreateRoleRequest_$reflection, RoleListResponse_$reflection, GetWarehousesResponse_$reflection, GetWarehousesRequest_$reflection, AccountsByOwnersResponse_$reflection, AccountsByOwnersRequest_$reflection, ProjectsByOwnersResponse_$reflection, ProjectsByOwnersRequest_$reflection, ManageRolesPageData_$reflection, ApiMessageResponseModule_mapMessages } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { SimpleJson_parse, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { Convert_serialize, Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";

export function getManageRolesPageData() {
    return singleton.Delay(() => {
        let req_3, req_2, req_1;
        return singleton.Bind(Http_send((req_3 = ((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/ManageRolesPage`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_3))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(ManageRolesPageData_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function projects(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(ProjectsByOwnersRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/ManageRolesPage/projects`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(ProjectsByOwnersResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function accounts(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(AccountsByOwnersRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/ManageRolesPage/accounts`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(AccountsByOwnersResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function warehouses(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(GetWarehousesRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/ManageRolesPage/warehouses`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(GetWarehousesResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function roles() {
    return singleton.Delay(() => {
        let req_3, req_2, req_1;
        return singleton.Bind(Http_send((req_3 = ((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Roles/List`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_3))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(RoleListResponse_$reflection()))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function createRole(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(CreateRoleRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/ManageRolesPage/createRole`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [true])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function updateRole(model) {
    return singleton.Delay(() => {
        let req_4, req_2, req_1;
        const json = Convert_serialize(model, createTypeInfo(CreateRoleRequest_$reflection()));
        return singleton.Bind(Http_send((req_4 = Http_content(new BodyContent(1, [json]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/ManageRolesPage/updateRole`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_4))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 200) ? (new FSharpResult$2(0, [true])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

