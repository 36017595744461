import { Record, Union } from "./fable_modules/fable-library.4.10.0/Types.js";
import { record_type, class_type, bool_type, union_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import { render as render_21, init as init_4, update as update_1, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "./Pages/AccountUpload.fs.js";
import { render as render_3, init as init_7, update as update_2, Msg_$reflection as Msg_$reflection_2, State_$reflection as State_$reflection_2 } from "./Pages/AdvancedShippingNoticeUpload.fs.js";
import { render as render_17, init as init_8, update as update_3, Msg_$reflection as Msg_$reflection_3, State_$reflection as State_$reflection_3 } from "./Pages/ReservationUpload.fs.js";
import { render as render_18, init as init_9, update as update_5, Msg_$reflection as Msg_$reflection_4, State_$reflection as State_$reflection_4 } from "./Pages/ShippingOrderUpload.fs.js";
import { render as render_5, init as init_10, update as update_6, Msg_$reflection as Msg_$reflection_5, State_$reflection as State_$reflection_5 } from "./Pages/InboundDetailsQuery.fs.js";
import { render as render_6, init as init_11, update as update_7, Msg_$reflection as Msg_$reflection_6, State_$reflection as State_$reflection_6 } from "./Pages/InboundSummaryQuery.fs.js";
import { render as render_14, init as init_17, update as update_15, Msg_$reflection as Msg_$reflection_7, State_$reflection as State_$reflection_7 } from "./Pages/MaterialsQuery.fs.js";
import { render as render_15, init as init_16, update as update_16, Msg_$reflection as Msg_$reflection_8, State_$reflection as State_$reflection_8 } from "./Pages/MaterialsUpload.fs.js";
import { render as render_10, init as init_12, update as update_8, Msg_$reflection as Msg_$reflection_9, State_$reflection as State_$reflection_9 } from "./Pages/OnHandDetailsQuery.fs.js";
import { render as render_11, init as init_13, update as update_9, Msg_$reflection as Msg_$reflection_10, State_$reflection as State_$reflection_10 } from "./Pages/OutboundDetailsQuery.fs.js";
import { render as render_12, init as init_14, update as update_10, Msg_$reflection as Msg_$reflection_11, State_$reflection as State_$reflection_11 } from "./Pages/OutboundSummaryQuery.fs.js";
import { render as render_13, init as init_15, update as update_14, Msg_$reflection as Msg_$reflection_12, State_$reflection as State_$reflection_12 } from "./Pages/ReservationsQuery.fs.js";
import { render as render_16, init as init_18, update as update_4, Msg_$reflection as Msg_$reflection_13, State_$reflection as State_$reflection_13 } from "./Pages/ShippingMatrixQuery.fs.js";
import { render as render_1, init as init_5, update as update_11, Msg_$reflection as Msg_$reflection_14, State_$reflection as State_$reflection_14 } from "./Pages/ManageRoles.fs.js";
import { render as render_2, init as init_6, update as update_12, Msg_$reflection as Msg_$reflection_15, State_$reflection as State_$reflection_15 } from "./Pages/ManageUsers.fs.js";
import { render as render_4, init as init_19, update as update_13, Msg_$reflection as Msg_$reflection_16, State_$reflection as State_$reflection_16 } from "./Pages/Home.fs.js";
import { render as render_7, update as update_18, init as init_1, Msg_$reflection as Msg_$reflection_17, State_$reflection as State_$reflection_17 } from "./Pages/Login.fs.js";
import { render as render_8, update as update_19, init as init_2, Msg_$reflection as Msg_$reflection_18, State_$reflection as State_$reflection_18 } from "./Pages/ResetPassword.fs.js";
import { render as render_9, update as update_20, init as init_3, Msg_$reflection as Msg_$reflection_19, State_$reflection as State_$reflection_19 } from "./Pages/ChangePassword.fs.js";
import { render as render_19, init as init_20, update as update_17, Msg_$reflection as Msg_$reflection_20, State_$reflection as State_$reflection_20 } from "./Pages/DownloadReport.fs.js";
import { ReportStatusChanged_$reflection, ApiMessageResponse_$reflection, UserResponse_$reflection } from "../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "./Extensions.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.10.0/Choice.js";
import { singleton, ofArray, tail, head, isEmpty } from "./fable_modules/fable-library.4.10.0/List.js";
import { Default_user } from "./Models.fs.js";
import { withUrl, signalr as signalr_1, configureLogging, build, register } from "./SignalR.fs.js";
import { RouterModule_router, RouterModule_encodeParts, RouterModule_nav, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "./fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Alert_texts, Alert_title, Alert_error, Toast_text, Toast_title, Toast_info } from "./Components/SweetAlert.fs.js";
import { Cmd_ofEffect } from "./fable_modules/Feliz.Router.4.0.0/../Fable.Elmish.4.1.0/cmd.fs.js";
import { logout, isAuthenticated } from "./API/AuthenticationAPI.fs.js";
import { createObj, equals } from "./fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "./fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { getCurrent } from "./API/UserAPI.fs.js";
import { userHasEitherClaim, userHasClaim } from "./Components/ClaimHelpers.fs.js";
import { createElement } from "react";
import * as react from "react";
import { isNullOrWhiteSpace, join } from "./fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_20 } from "./Pages/NotFound.fs.js";
import { render as render_22 } from "./Components/Loader.fs.js";

export class Url extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AccountUpload", "AdvancedShippingNoticeUpload", "ReservationUpload", "ShippingOrderUpload", "InboundDetailsQuery", "InboundSummaryQuery", "OnHandDetailsQuery", "OutboundDetailsQuery", "OutboundSummaryQuery", "ReservationsQuery", "MaterialsUpload", "MaterialsQuery", "ShippingMatrixQuery", "Home", "Login", "ResetPassword", "ChangePassword", "ManageRoles", "ManageUsers", "ScheduledReports", "NotFound"];
    }
}

export function Url_$reflection() {
    return union_type("FootPrint.Portal.UI.Application.Url", [], Url, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Page extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AccountUpload", "AdvancedShippingNoticeUpload", "ReservationUpload", "ShippingOrderUpload", "InboundDetailsQuery", "InboundSummaryQuery", "MaterialsQuery", "MaterialsUpload", "OnHandDetailsQuery", "OutboundDetailsQuery", "OutboundSummaryQuery", "ReservationsQuery", "ShippingMatrixQuery", "ManageRoles", "ManageUsers", "Home", "Login", "ResetPassword", "ChangePassword", "DownloadReport", "NotFound"];
    }
}

export function Page_$reflection() {
    return union_type("FootPrint.Portal.UI.Application.Page", [], Page, () => [[["Item", State_$reflection_1()]], [["Item", State_$reflection_2()]], [["Item", State_$reflection_3()]], [["Item", State_$reflection_4()]], [["Item", State_$reflection_5()]], [["Item", State_$reflection_6()]], [["Item", State_$reflection_7()]], [["Item", State_$reflection_8()]], [["Item", State_$reflection_9()]], [["Item", State_$reflection_10()]], [["Item", State_$reflection_11()]], [["Item", State_$reflection_12()]], [["Item", State_$reflection_13()]], [["Item", State_$reflection_14()]], [["Item", State_$reflection_15()]], [["Item", State_$reflection_16()]], [["Item", State_$reflection_17()]], [["Item", State_$reflection_18()]], [["Item", State_$reflection_19()]], [["Item", State_$reflection_20()]], []]);
}

export class State extends Record {
    constructor(IsMenuItemsExpanded, CurrentUrl, CurrentPage, CurrentUserDeferred, SignalRHub) {
        super();
        this.IsMenuItemsExpanded = IsMenuItemsExpanded;
        this.CurrentUrl = CurrentUrl;
        this.CurrentPage = CurrentPage;
        this.CurrentUserDeferred = CurrentUserDeferred;
        this.SignalRHub = SignalRHub;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Application.State", [], State, () => [["IsMenuItemsExpanded", bool_type], ["CurrentUrl", Url_$reflection()], ["CurrentPage", Page_$reflection()], ["CurrentUserDeferred", Deferred$1_$reflection(UserResponse_$reflection())], ["SignalRHub", class_type("SignalR.IHubConnection")]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AccountUploadMsg", "AdvancedShippingNoticeUploadMsg", "ReservationUploadMsg", "ShippingOrderUploadMsg", "InboundDetailsQueryMsg", "InboundSummaryQueryMsg", "MaterialsQueryMsg", "MaterialsUploadMsg", "OnHandDetailsQueryMsg", "OutboundDetailsQueryMsg", "OutboundSummaryQueryMsg", "ReservationsQueryMsg", "ShippingMatrixQueryMsg", "ManageRolesMsg", "ManageUsersMsg", "SetIsMenuItemsExpanded", "HomeMsg", "LoginMsg", "ResetPasswordMsg", "ChangePasswordMsg", "DownloadReportMsg", "UrlChanged", "GetCurrentUser", "SignalRReportStatusChanged"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Application.Msg", [], Msg, () => [[["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]], [["Item", Msg_$reflection_3()]], [["Item", Msg_$reflection_4()]], [["Item", Msg_$reflection_5()]], [["Item", Msg_$reflection_6()]], [["Item", Msg_$reflection_7()]], [["Item", Msg_$reflection_8()]], [["Item", Msg_$reflection_9()]], [["Item", Msg_$reflection_10()]], [["Item", Msg_$reflection_11()]], [["Item", Msg_$reflection_12()]], [["Item", Msg_$reflection_13()]], [["Item", Msg_$reflection_14()]], [["Item", Msg_$reflection_15()]], [], [["Item", Msg_$reflection_16()]], [["Item", Msg_$reflection_17()]], [["Item", Msg_$reflection_18()]], [["Item", Msg_$reflection_19()]], [["Item", Msg_$reflection_20()]], [["Item", Url_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserResponse_$reflection(), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", UserResponse_$reflection()]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", ReportStatusChanged_$reflection()]]]);
}

function parseUrl(segments) {
    let matchResult;
    if (isEmpty(segments)) {
        matchResult = 18;
    }
    else {
        switch (head(segments)) {
            case "account-upload": {
                if (isEmpty(tail(segments))) {
                    matchResult = 0;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "advanced-shipping-notice-upload": {
                if (isEmpty(tail(segments))) {
                    matchResult = 1;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "reservation-upload": {
                if (isEmpty(tail(segments))) {
                    matchResult = 2;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "shipping-order-upload": {
                if (isEmpty(tail(segments))) {
                    matchResult = 3;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "inbound-details-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 4;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "inbound-summary-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 5;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "materials-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 6;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "materials-upload": {
                if (isEmpty(tail(segments))) {
                    matchResult = 7;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "on-hand-details-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 8;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "outbound-details-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 9;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "outbound-summary-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 10;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "reservations-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 11;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "shipping-matrix-query": {
                if (isEmpty(tail(segments))) {
                    matchResult = 12;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "reset-password": {
                if (isEmpty(tail(segments))) {
                    matchResult = 13;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "change-password": {
                if (isEmpty(tail(segments))) {
                    matchResult = 14;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "manage-roles": {
                if (isEmpty(tail(segments))) {
                    matchResult = 15;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "manage-users": {
                if (isEmpty(tail(segments))) {
                    matchResult = 16;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            case "scheduled-reports": {
                if (isEmpty(tail(segments))) {
                    matchResult = 17;
                }
                else {
                    matchResult = 19;
                }
                break;
            }
            default:
                matchResult = 19;
        }
    }
    switch (matchResult) {
        case 0:
            return new Url(0, []);
        case 1:
            return new Url(1, []);
        case 2:
            return new Url(2, []);
        case 3:
            return new Url(3, []);
        case 4:
            return new Url(4, []);
        case 5:
            return new Url(5, []);
        case 6:
            return new Url(11, []);
        case 7:
            return new Url(10, []);
        case 8:
            return new Url(6, []);
        case 9:
            return new Url(7, []);
        case 10:
            return new Url(8, []);
        case 11:
            return new Url(9, []);
        case 12:
            return new Url(12, []);
        case 13:
            return new Url(15, []);
        case 14:
            return new Url(16, []);
        case 15:
            return new Url(17, []);
        case 16:
            return new Url(18, []);
        case 17:
            return new Url(19, []);
        case 18:
            return new Url(13, []);
        default:
            return new Url(20, []);
    }
}

function getCurrentUser(state) {
    const matchValue = state.CurrentUserDeferred;
    if (matchValue.tag === 2) {
        return matchValue.fields[0];
    }
    else {
        return Default_user;
    }
}

export function init() {
    let signalR;
    const patternInput = register("ReportStatusChanged", (Item) => (new Msg(23, [Item])), build(configureLogging(1, (signalR = (new signalr_1.HubConnectionBuilder()), withUrl(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api`, signalR)))));
    const patternInput_1 = init_1();
    const patternInput_2 = init_2();
    const patternInput_3 = init_3();
    let patternInput_4;
    const matchValue = parseUrl(RouterModule_urlSegments(window.location.hash, 1));
    patternInput_4 = ((matchValue.tag === 15) ? [new Page(17, [patternInput_2[0]]), new Url(15, [])] : ((matchValue.tag === 16) ? [new Page(18, [patternInput_3[0]]), new Url(16, [])] : [new Page(16, [patternInput_1[0]]), new Url(14, [])]));
    return [new State(false, patternInput_4[1], patternInput_4[0], new Deferred$1(0, []), patternInput[0]), Cmd_batch(ofArray([Cmd_map((Item_1) => (new Msg(17, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(18, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(19, [Item_3])), patternInput_3[1]), patternInput[1]]))];
}

export function update(msg, state) {
    let input_3;
    const currentUser = getCurrentUser(state);
    const matchValue = state.CurrentPage;
    let matchResult, statusChange, pageMsg, pageState, pageMsg_1, pageState_2, pageMsg_2, pageState_4, pageMsg_3, pageState_6, pageMsg_4, pageState_8, pageMsg_5, pageState_10, pageMsg_6, pageState_12, pageMsg_7, pageState_14, pageMsg_8, pageState_16, pageMsg_9, pageState_18, pageMsg_10, pageState_20, pageMsg_11, pageState_22, pageMsg_12, pageState_24, pageMsg_13, pageState_26, pageMsg_14, pageState_28, pageMsg_15, pageState_30, pageMsg_16, pageState_32, pageMsg_17, pageState_34, pageMsg_18, pageState_36, pageMsg_19, pageState_38, result, url_1, url_2;
    switch (msg.tag) {
        case 15: {
            matchResult = 1;
            break;
        }
        case 0: {
            if (matchValue.tag === 0) {
                matchResult = 2;
                pageMsg = msg.fields[0];
                pageState = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 1: {
            if (matchValue.tag === 1) {
                matchResult = 3;
                pageMsg_1 = msg.fields[0];
                pageState_2 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 2: {
            if (matchValue.tag === 2) {
                matchResult = 4;
                pageMsg_2 = msg.fields[0];
                pageState_4 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 12: {
            if (matchValue.tag === 12) {
                matchResult = 5;
                pageMsg_3 = msg.fields[0];
                pageState_6 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 3: {
            if (matchValue.tag === 3) {
                matchResult = 6;
                pageMsg_4 = msg.fields[0];
                pageState_8 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 4: {
            if (matchValue.tag === 4) {
                matchResult = 7;
                pageMsg_5 = msg.fields[0];
                pageState_10 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 5: {
            if (matchValue.tag === 5) {
                matchResult = 8;
                pageMsg_6 = msg.fields[0];
                pageState_12 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 8: {
            if (matchValue.tag === 8) {
                matchResult = 9;
                pageMsg_7 = msg.fields[0];
                pageState_14 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 9: {
            if (matchValue.tag === 9) {
                matchResult = 10;
                pageMsg_8 = msg.fields[0];
                pageState_16 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 10: {
            if (matchValue.tag === 10) {
                matchResult = 11;
                pageMsg_9 = msg.fields[0];
                pageState_18 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 13: {
            if (matchValue.tag === 13) {
                matchResult = 12;
                pageMsg_10 = msg.fields[0];
                pageState_20 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 14: {
            if (matchValue.tag === 14) {
                matchResult = 13;
                pageMsg_11 = msg.fields[0];
                pageState_22 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 16: {
            if (matchValue.tag === 15) {
                matchResult = 14;
                pageMsg_12 = msg.fields[0];
                pageState_24 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 11: {
            if (matchValue.tag === 11) {
                matchResult = 15;
                pageMsg_13 = msg.fields[0];
                pageState_26 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 6: {
            if (matchValue.tag === 6) {
                matchResult = 16;
                pageMsg_14 = msg.fields[0];
                pageState_28 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 7: {
            if (matchValue.tag === 7) {
                matchResult = 17;
                pageMsg_15 = msg.fields[0];
                pageState_30 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 20: {
            if (matchValue.tag === 19) {
                matchResult = 18;
                pageMsg_16 = msg.fields[0];
                pageState_32 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 17: {
            if (matchValue.tag === 16) {
                matchResult = 19;
                pageMsg_17 = msg.fields[0];
                pageState_34 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 18: {
            if (matchValue.tag === 17) {
                matchResult = 20;
                pageMsg_18 = msg.fields[0];
                pageState_36 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 19: {
            if (matchValue.tag === 18) {
                matchResult = 21;
                pageMsg_19 = msg.fields[0];
                pageState_38 = matchValue.fields[0];
            }
            else {
                matchResult = 26;
            }
            break;
        }
        case 22: {
            switch (msg.fields[0].tag) {
                case 0: {
                    matchResult = 22;
                    break;
                }
                case 2: {
                    matchResult = 23;
                    result = msg.fields[0].fields[0];
                    break;
                }
                default:
                    matchResult = 26;
            }
            break;
        }
        case 21: {
            if (isAuthenticated()) {
                matchResult = 24;
                url_1 = msg.fields[0];
            }
            else {
                matchResult = 25;
                url_2 = msg.fields[0];
            }
            break;
        }
        default: {
            matchResult = 0;
            statusChange = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0:
            return [state, Toast_info(Toast_title("Report Creation Status", Toast_text(statusChange.ReportStatus)))];
        case 1:
            return [new State(!state.IsMenuItemsExpanded, state.CurrentUrl, state.CurrentPage, state.CurrentUserDeferred, state.SignalRHub), Cmd_none()];
        case 2: {
            const patternInput = update_1(pageMsg, pageState);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(0, [patternInput[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item) => (new Msg(0, [Item])), patternInput[1])];
        }
        case 3: {
            const patternInput_1 = update_2(pageMsg_1, pageState_2);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(1, [patternInput_1[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_1) => (new Msg(1, [Item_1])), patternInput_1[1])];
        }
        case 4: {
            const patternInput_2 = update_3(pageMsg_2, pageState_4);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(2, [patternInput_2[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_2) => (new Msg(2, [Item_2])), patternInput_2[1])];
        }
        case 5: {
            const patternInput_3 = update_4(pageMsg_3, pageState_6);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(12, [patternInput_3[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_3) => (new Msg(12, [Item_3])), patternInput_3[1])];
        }
        case 6: {
            const patternInput_4 = update_5(pageMsg_4, pageState_8);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(3, [patternInput_4[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_4) => (new Msg(3, [Item_4])), patternInput_4[1])];
        }
        case 7: {
            const patternInput_5 = update_6(pageMsg_5, pageState_10);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(4, [patternInput_5[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_5) => (new Msg(4, [Item_5])), patternInput_5[1])];
        }
        case 8: {
            const patternInput_6 = update_7(pageMsg_6, pageState_12);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(5, [patternInput_6[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_6) => (new Msg(5, [Item_6])), patternInput_6[1])];
        }
        case 9: {
            const patternInput_7 = update_8(pageMsg_7, pageState_14);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(8, [patternInput_7[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_7) => (new Msg(8, [Item_7])), patternInput_7[1])];
        }
        case 10: {
            const patternInput_8 = update_9(pageMsg_8, pageState_16);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(9, [patternInput_8[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_8) => (new Msg(9, [Item_8])), patternInput_8[1])];
        }
        case 11: {
            const patternInput_9 = update_10(pageMsg_9, pageState_18);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(10, [patternInput_9[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_9) => (new Msg(10, [Item_9])), patternInput_9[1])];
        }
        case 12: {
            const patternInput_10 = update_11(pageMsg_10, pageState_20);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(13, [patternInput_10[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_10) => (new Msg(13, [Item_10])), patternInput_10[1])];
        }
        case 13: {
            const patternInput_11 = update_12(pageMsg_11, pageState_22);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(14, [patternInput_11[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_11) => (new Msg(14, [Item_11])), patternInput_11[1])];
        }
        case 14: {
            const patternInput_12 = update_13(pageMsg_12, pageState_24);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(15, [patternInput_12[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_12) => (new Msg(16, [Item_12])), patternInput_12[1])];
        }
        case 15: {
            const patternInput_13 = update_14(pageMsg_13, pageState_26);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(11, [patternInput_13[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_13) => (new Msg(11, [Item_13])), patternInput_13[1])];
        }
        case 16: {
            const patternInput_14 = update_15(pageMsg_14, pageState_28);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(6, [patternInput_14[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_14) => (new Msg(6, [Item_14])), patternInput_14[1])];
        }
        case 17: {
            const patternInput_15 = update_16(pageMsg_15, pageState_30);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(7, [patternInput_15[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_15) => (new Msg(7, [Item_15])), patternInput_15[1])];
        }
        case 18: {
            const patternInput_16 = update_17(pageMsg_16, pageState_32);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(19, [patternInput_16[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_16) => (new Msg(20, [Item_16])), patternInput_16[1])];
        }
        case 19: {
            const patternInput_17 = update_18(pageMsg_17, pageState_34);
            if (patternInput_17[2].tag === 0) {
                return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, state.CurrentPage, new Deferred$1(0, []), state.SignalRHub), Cmd_batch(ofArray([singleton((dispatch) => {
                    dispatch(new Msg(22, [new AsyncMsg$1(0, [])]));
                }), Cmd_ofEffect((_arg) => {
                    RouterModule_nav(singleton("/"), 1, 1);
                })]))];
            }
            else {
                return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(16, [patternInput_17[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_17) => (new Msg(17, [Item_17])), patternInput_17[1])];
            }
        }
        case 20: {
            const patternInput_18 = update_19(pageMsg_18, pageState_36);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(17, [patternInput_18[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_18) => (new Msg(18, [Item_18])), patternInput_18[1])];
        }
        case 21: {
            const patternInput_19 = update_20(pageMsg_19, pageState_38);
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, new Page(18, [patternInput_19[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_19) => (new Msg(19, [Item_19])), patternInput_19[1])];
        }
        case 22:
            if (!isAuthenticated()) {
                return [state, Cmd_none()];
            }
            else if (equals(state.CurrentUserDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, state.CurrentPage, new Deferred$1(1, []), state.SignalRHub), Cmd_OfAsyncWith_perform((x) => {
                    Cmd_OfAsync_start(x);
                }, getCurrent, void 0, (arg) => (new Msg(22, [new AsyncMsg$1(2, [arg])])))];
            }
        case 23: {
            let command_2;
            const input_1 = result;
            command_2 = ((input_1.tag === 1) ? Alert_error(Alert_title("Get Current User", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_ofEffect((_arg_2) => {
                RouterModule_nav(singleton("/"), 1, 1);
            }));
            return [new State(state.IsMenuItemsExpanded, state.CurrentUrl, state.CurrentPage, new Deferred$1(2, [(input_3 = result, (input_3.tag === 1) ? Default_user : input_3.fields[0])]), state.SignalRHub), command_2];
        }
        case 24: {
            const canManageAccounts = userHasClaim(currentUser, 4);
            const canUploadASNs = userHasClaim(currentUser, 1);
            const canUploadReservations = userHasClaim(currentUser, 2);
            const canUpload940s = userHasClaim(currentUser, 3);
            const canMaterialsUpload = userHasClaim(currentUser, 5);
            let matchResult_1;
            switch (url_1.tag) {
                case 17: {
                    matchResult_1 = 1;
                    break;
                }
                case 18: {
                    matchResult_1 = 2;
                    break;
                }
                case 1: {
                    if (canUploadASNs) {
                        matchResult_1 = 3;
                    }
                    else {
                        matchResult_1 = 21;
                    }
                    break;
                }
                case 2: {
                    if (canUploadReservations) {
                        matchResult_1 = 4;
                    }
                    else {
                        matchResult_1 = 21;
                    }
                    break;
                }
                case 3: {
                    if (canUpload940s) {
                        matchResult_1 = 5;
                    }
                    else {
                        matchResult_1 = 21;
                    }
                    break;
                }
                case 4: {
                    matchResult_1 = 6;
                    break;
                }
                case 5: {
                    matchResult_1 = 7;
                    break;
                }
                case 6: {
                    matchResult_1 = 8;
                    break;
                }
                case 7: {
                    matchResult_1 = 9;
                    break;
                }
                case 8: {
                    matchResult_1 = 10;
                    break;
                }
                case 9: {
                    matchResult_1 = 11;
                    break;
                }
                case 10: {
                    if (canMaterialsUpload) {
                        matchResult_1 = 12;
                    }
                    else {
                        matchResult_1 = 21;
                    }
                    break;
                }
                case 11: {
                    matchResult_1 = 13;
                    break;
                }
                case 12: {
                    matchResult_1 = 14;
                    break;
                }
                case 13: {
                    matchResult_1 = 15;
                    break;
                }
                case 14: {
                    matchResult_1 = 16;
                    break;
                }
                case 15: {
                    matchResult_1 = 17;
                    break;
                }
                case 16: {
                    matchResult_1 = 18;
                    break;
                }
                case 19: {
                    matchResult_1 = 19;
                    break;
                }
                case 20: {
                    matchResult_1 = 20;
                    break;
                }
                default:
                    if (canManageAccounts) {
                        matchResult_1 = 0;
                    }
                    else {
                        matchResult_1 = 21;
                    }
            }
            switch (matchResult_1) {
                case 0: {
                    const patternInput_20 = init_4();
                    return [new State(false, new Url(0, []), new Page(0, [patternInput_20[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_22) => (new Msg(0, [Item_22])), patternInput_20[1])];
                }
                case 1: {
                    const patternInput_21 = init_5(currentUser);
                    return [new State(false, new Url(17, []), new Page(13, [patternInput_21[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_23) => (new Msg(13, [Item_23])), patternInput_21[1])];
                }
                case 2: {
                    const patternInput_22 = init_6();
                    return [new State(false, new Url(18, []), new Page(14, [patternInput_22[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_24) => (new Msg(14, [Item_24])), patternInput_22[1])];
                }
                case 3: {
                    const patternInput_23 = init_7();
                    return [new State(false, new Url(1, []), new Page(1, [patternInput_23[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_25) => (new Msg(1, [Item_25])), patternInput_23[1])];
                }
                case 4: {
                    const patternInput_24 = init_8();
                    return [new State(false, new Url(2, []), new Page(2, [patternInput_24[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_26) => (new Msg(2, [Item_26])), patternInput_24[1])];
                }
                case 5: {
                    const patternInput_25 = init_9();
                    return [new State(false, new Url(3, []), new Page(3, [patternInput_25[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_27) => (new Msg(3, [Item_27])), patternInput_25[1])];
                }
                case 6: {
                    const patternInput_26 = init_10(currentUser);
                    return [new State(false, new Url(4, []), new Page(4, [patternInput_26[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_28) => (new Msg(4, [Item_28])), patternInput_26[1])];
                }
                case 7: {
                    const patternInput_27 = init_11(currentUser);
                    return [new State(false, new Url(5, []), new Page(5, [patternInput_27[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_29) => (new Msg(5, [Item_29])), patternInput_27[1])];
                }
                case 8: {
                    const patternInput_28 = init_12(currentUser);
                    return [new State(false, new Url(6, []), new Page(8, [patternInput_28[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_30) => (new Msg(8, [Item_30])), patternInput_28[1])];
                }
                case 9: {
                    const patternInput_29 = init_13(currentUser);
                    return [new State(false, new Url(7, []), new Page(9, [patternInput_29[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_31) => (new Msg(9, [Item_31])), patternInput_29[1])];
                }
                case 10: {
                    const patternInput_30 = init_14(currentUser);
                    return [new State(false, new Url(8, []), new Page(10, [patternInput_30[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_32) => (new Msg(10, [Item_32])), patternInput_30[1])];
                }
                case 11: {
                    const patternInput_31 = init_15(currentUser);
                    return [new State(false, new Url(9, []), new Page(11, [patternInput_31[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_33) => (new Msg(11, [Item_33])), patternInput_31[1])];
                }
                case 12: {
                    const patternInput_32 = init_16();
                    return [new State(false, new Url(10, []), new Page(7, [patternInput_32[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_34) => (new Msg(7, [Item_34])), patternInput_32[1])];
                }
                case 13: {
                    const patternInput_33 = init_17(currentUser);
                    return [new State(false, new Url(11, []), new Page(6, [patternInput_33[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_35) => (new Msg(6, [Item_35])), patternInput_33[1])];
                }
                case 14: {
                    const patternInput_34 = init_18(currentUser);
                    return [new State(false, new Url(12, []), new Page(12, [patternInput_34[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_36) => (new Msg(12, [Item_36])), patternInput_34[1])];
                }
                case 15: {
                    const patternInput_35 = init_19(currentUser);
                    return [new State(false, new Url(13, []), new Page(15, [patternInput_35[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_37) => (new Msg(16, [Item_37])), patternInput_35[1])];
                }
                case 16: {
                    const patternInput_36 = init_1();
                    return [new State(false, new Url(14, []), new Page(16, [patternInput_36[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_38) => (new Msg(17, [Item_38])), patternInput_36[1])];
                }
                case 17: {
                    const patternInput_37 = init_2();
                    return [new State(false, new Url(15, []), new Page(17, [patternInput_37[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_39) => (new Msg(18, [Item_39])), patternInput_37[1])];
                }
                case 18: {
                    const patternInput_38 = init_3();
                    return [new State(false, new Url(16, []), new Page(18, [patternInput_38[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_40) => (new Msg(19, [Item_40])), patternInput_38[1])];
                }
                case 19: {
                    const patternInput_39 = init_20(currentUser);
                    return [new State(false, new Url(19, []), new Page(19, [patternInput_39[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_41) => (new Msg(20, [Item_41])), patternInput_39[1])];
                }
                case 20:
                    return [new State(false, new Url(20, []), new Page(20, []), state.CurrentUserDeferred, state.SignalRHub), Cmd_none()];
                default:
                    return [new State(false, new Url(20, []), new Page(20, []), state.CurrentUserDeferred, state.SignalRHub), Cmd_none()];
            }
        }
        case 25:
            switch (url_2.tag) {
                case 15: {
                    const patternInput_40 = init_2();
                    return [new State(false, new Url(15, []), new Page(17, [patternInput_40[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_42) => (new Msg(18, [Item_42])), patternInput_40[1])];
                }
                case 16: {
                    const patternInput_41 = init_3();
                    return [new State(false, new Url(16, []), new Page(18, [patternInput_41[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_43) => (new Msg(19, [Item_43])), patternInput_41[1])];
                }
                default: {
                    const patternInput_42 = init_1();
                    return [new State(false, new Url(14, []), new Page(16, [patternInput_42[0]]), state.CurrentUserDeferred, state.SignalRHub), Cmd_map((Item_44) => (new Msg(17, [Item_44])), patternInput_42[1])];
                }
            }
        default:
            return [state, Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_17, elems_3, elems_1, elems, elems_2, elems_16, elems_10, elems_5, elems_4, elems_7, elems_6, elems_9, elems_8, elems_15, elems_14, elems_13, elems_11, elems_12, elems_18, elems_20, elems_19, elems_25, elems_24, elems_23, elems_21, elems_22, elements;
    const currentUser = getCurrentUser(state);
    const navbar = createElement("nav", createObj(ofArray([["aria-label", "main navigation"], ["className", join(" ", ["navbar", "has-shadow", "is-fixed-top"])], ["role", join(" ", ["navigation"])], (elems_17 = [createElement("div", createObj(ofArray([["className", "navbar-brand"], (elems_3 = [createElement("a", createObj(ofArray([["className", "navbar-item"], ["href", RouterModule_encodeParts(singleton("/"), 1)], (elems_1 = [createElement("a", createObj(ofArray([["className", join(" ", ["navbar-item"])], (elems = [createElement("img", {
        src: "https://craneww-assets.azureedge.net/assets/crane-logo.svg",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("a", createObj(ofArray([["aria-expanded", false], ["aria-label", "menu"], ["className", "navbar-burger"], ["className", join(" ", toList(delay(() => append(singleton_1("navbar-burger"), delay(() => (state.IsMenuItemsExpanded ? singleton_1("is-active") : empty()))))))], ["role", join(" ", ["button"])], ["onClick", (_arg) => {
        dispatch(new Msg(15, []));
    }], (elems_2 = [createElement("span", {
        "aria-hidden": true,
    }), createElement("span", {
        "aria-hidden": true,
    }), createElement("span", {
        "aria-hidden": true,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("navbar-menu"), delay(() => (state.IsMenuItemsExpanded ? singleton_1("is-active") : empty()))))))], (elems_16 = [createElement("div", createObj(ofArray([["className", "navbar-start"], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => append(singleton_1("has-dropdown"), delay(() => append(singleton_1("is-hoverable"), delay(() => (!userHasEitherClaim(currentUser, ofArray([4, 5, 14])) ? singleton_1("is-hidden") : empty()))))))))))], (elems_5 = [createElement("a", {
        className: join(" ", ["navbar-link", "has-text-link", "has-text-weight-semibold"]),
        children: "Administration",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["navbar-dropdown", "is-size-7"])], (elems_4 = [createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 4) ? singleton_1("is-hidden") : singleton_1(""))))))),
        href: RouterModule_encodeParts(singleton("/account-upload"), 1),
        children: "Accounts",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 5) ? singleton_1("is-hidden") : singleton_1(""))))))),
        href: RouterModule_encodeParts(singleton("/materials-upload"), 1),
        children: "Materials",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 14) ? singleton_1("is-hidden") : singleton_1(""))))))),
        href: RouterModule_encodeParts(singleton("/manage-roles"), 1),
        children: "Manage Roles",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 14) ? singleton_1("is-hidden") : singleton_1(""))))))),
        href: RouterModule_encodeParts(singleton("/manage-users"), 1),
        children: "Manage User",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["navbar-item", "has-dropdown", "is-hoverable"])], (elems_7 = [createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-link"), delay(() => append(singleton_1("has-text-link"), delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => (!userHasEitherClaim(currentUser, ofArray([1, 2, 3])) ? singleton_1("is-hidden") : empty())))))))))),
        children: "Orders",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["navbar-dropdown", "is-size-7"])], (elems_6 = [createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 1) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/advanced-shipping-notice-upload"), 1),
        children: "Advanced Shipping Notices",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 2) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/reservation-upload"), 1),
        children: "Reservations",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 3) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/shipping-order-upload"), 1),
        children: "Shipping Orders",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["navbar-item", "has-dropdown", "is-hoverable"])], (elems_9 = [createElement("a", {
        className: join(" ", ["navbar-link", "has-text-link", "has-text-weight-semibold"]),
        className: join(" ", toList(delay(() => append(singleton_1("navbar-link"), delay(() => append(singleton_1("has-text-link"), delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => (!userHasEitherClaim(currentUser, ofArray([6, 7, 8, 9, 10, 11, 12, 13])) ? singleton_1("is-hidden") : empty())))))))))),
        children: "Queries",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["navbar-dropdown", "is-size-7"])], (elems_8 = [createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 6) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/inbound-details-query"), 1),
        children: "Inbound Details",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 7) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/inbound-summary-query"), 1),
        children: "Inbound Summary",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 8) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/on-hand-details-query"), 1),
        children: "On Hand Details",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 9) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/outbound-details-query"), 1),
        children: "Outbound Details",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 10) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/outbound-summary-query"), 1),
        children: "Outbound Summary",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasEitherClaim(currentUser, singleton(11)) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/reservations-query"), 1),
        children: "Reservations",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 12) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/materials-query"), 1),
        children: "Materials",
    }), createElement("a", {
        className: join(" ", toList(delay(() => append(singleton_1("navbar-item"), delay(() => (!userHasClaim(currentUser, 13) ? singleton_1("is-hidden") : empty())))))),
        href: RouterModule_encodeParts(singleton("/shipping-matrix-query"), 1),
        children: "Shipping Matrix",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", "navbar-end"], (elems_15 = [createElement("a", {
        className: join(" ", ["navbar-item", "has-text-link", "has-text-weight-semibold"]),
        href: "https://c-view.craneww.com/",
        target: "_blank",
        children: "C-View",
    }), createElement("a", {
        className: join(" ", ["navbar-item", "has-text-link", "has-text-weight-semibold"]),
        href: "https://webtracker.craneww.com/",
        target: "_blank",
        children: "Shipment Tracker",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["navbar-item", "has-dropdown", "is-hoverable"])], (elems_14 = [createElement("a", {
        className: join(" ", ["navbar-link", "has-text-link", "has-text-weight-semibold"]),
        children: isNullOrWhiteSpace(currentUser.FullName) ? "Loading..." : currentUser.FullName,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["navbar-dropdown", "is-size-7"])], (elems_13 = [createElement("div", createObj(singleton((elems_11 = [createElement("a", {
        className: "navbar-item",
        children: "Reports",
        onClick: (_arg_1) => {
            RouterModule_nav(singleton("/scheduled-reports"), 1, 1);
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])))), createElement("div", createObj(singleton((elems_12 = [createElement("a", {
        className: "navbar-item",
        children: "Log Out",
        onClick: (_arg_2) => {
            logout();
            RouterModule_nav(singleton("/"), 1, 1);
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])));
    const main = createElement("main", createObj(ofArray([["className", join(" ", ["mb-6", "p-6", "is-size-7"])], (elems_18 = toList(delay(() => {
        const matchValue = state.CurrentPage;
        return (matchValue.tag === 13) ? singleton_1(render_1(matchValue.fields[0], (arg_1) => {
            dispatch(new Msg(13, [arg_1]));
        })) : ((matchValue.tag === 14) ? singleton_1(render_2(matchValue.fields[0], (arg_2) => {
            dispatch(new Msg(14, [arg_2]));
        })) : ((matchValue.tag === 1) ? singleton_1(render_3(matchValue.fields[0], (arg_3) => {
            dispatch(new Msg(1, [arg_3]));
        })) : ((matchValue.tag === 15) ? singleton_1(render_4(matchValue.fields[0], (arg_4) => {
            dispatch(new Msg(16, [arg_4]));
        })) : ((matchValue.tag === 4) ? singleton_1(render_5(matchValue.fields[0], (arg_5) => {
            dispatch(new Msg(4, [arg_5]));
        })) : ((matchValue.tag === 5) ? singleton_1(render_6(matchValue.fields[0], (arg_6) => {
            dispatch(new Msg(5, [arg_6]));
        })) : ((matchValue.tag === 16) ? singleton_1(render_7(matchValue.fields[0], (arg_7) => {
            dispatch(new Msg(17, [arg_7]));
        })) : ((matchValue.tag === 17) ? singleton_1(render_8(matchValue.fields[0], (arg_8) => {
            dispatch(new Msg(18, [arg_8]));
        })) : ((matchValue.tag === 18) ? singleton_1(render_9(matchValue.fields[0], (arg_9) => {
            dispatch(new Msg(19, [arg_9]));
        })) : ((matchValue.tag === 8) ? singleton_1(render_10(matchValue.fields[0], (arg_10) => {
            dispatch(new Msg(8, [arg_10]));
        })) : ((matchValue.tag === 9) ? singleton_1(render_11(matchValue.fields[0], (arg_11) => {
            dispatch(new Msg(9, [arg_11]));
        })) : ((matchValue.tag === 10) ? singleton_1(render_12(matchValue.fields[0], (arg_12) => {
            dispatch(new Msg(10, [arg_12]));
        })) : ((matchValue.tag === 11) ? singleton_1(render_13(matchValue.fields[0], (arg_13) => {
            dispatch(new Msg(11, [arg_13]));
        })) : ((matchValue.tag === 6) ? singleton_1(render_14(matchValue.fields[0], (arg_14) => {
            dispatch(new Msg(6, [arg_14]));
        })) : ((matchValue.tag === 7) ? singleton_1(render_15(matchValue.fields[0], (arg_15) => {
            dispatch(new Msg(7, [arg_15]));
        })) : ((matchValue.tag === 12) ? singleton_1(render_16(matchValue.fields[0], (arg_16) => {
            dispatch(new Msg(12, [arg_16]));
        })) : ((matchValue.tag === 2) ? singleton_1(render_17(matchValue.fields[0], (arg_17) => {
            dispatch(new Msg(2, [arg_17]));
        })) : ((matchValue.tag === 3) ? singleton_1(render_18(matchValue.fields[0], (arg_18) => {
            dispatch(new Msg(3, [arg_18]));
        })) : ((matchValue.tag === 19) ? singleton_1(render_19(matchValue.fields[0], (arg_19) => {
            dispatch(new Msg(20, [arg_19]));
        })) : ((matchValue.tag === 20) ? singleton_1(render_20()) : singleton_1(render_21(matchValue.fields[0], (arg) => {
            dispatch(new Msg(0, [arg]));
        })))))))))))))))))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])));
    const showSpinner = createElement("main", createObj(ofArray([["className", join(" ", ["mb-6", "p-6", "is-size-7"])], (elems_20 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-mobile", "is-centered", "is-vcentered"])], ["style", {
        height: 80 + "vh",
    }], (elems_19 = [render_22()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])));
    const footer = createElement("footer", createObj(ofArray([["className", join(" ", ["footer", "is-fixed-bottom"])], (elems_25 = [createElement("div", createObj(ofArray([["className", join(" ", ["content", "has-text-centered", "is-size-7"])], (elems_24 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_23 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_21 = [createElement("a", {
        className: join(" ", ["mx-4", "has-text-light"]),
        href: "https://craneww.com/privacy-policy/",
        target: "_blank",
        children: "Privacy Policy",
    }), createElement("a", {
        className: join(" ", ["mx-4", "has-text-light"]),
        href: "https://craneww.com/terms-of-service/",
        target: "_blank",
        children: "Terms of Service",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-hidden-mobile"])], (elems_22 = [createElement("a", {
        className: join(" ", ["has-text-light"]),
        href: "https://craneww.com/",
        target: "_blank",
        children: "Crane Worldwide Logistics",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])));
    return RouterModule_router(createObj(ofArray([["onUrlChanged", (arg_21) => {
        dispatch(new Msg(21, [parseUrl(arg_21)]));
    }], (elements = toList(delay(() => (((equals(state.CurrentUrl, new Url(14, [])) ? true : equals(state.CurrentUrl, new Url(15, []))) ? true : equals(state.CurrentUrl, new Url(16, []))) ? append((state.CurrentUserDeferred.tag === 1) ? singleton_1(showSpinner) : singleton_1(main), delay(() => singleton_1(footer))) : append((state.CurrentUserDeferred.tag === 1) ? singleton_1(showSpinner) : append(singleton_1(navbar), delay(() => singleton_1(main))), delay(() => singleton_1(footer)))))), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

